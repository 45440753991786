import { FC, HTMLProps } from 'react';
import { useQuery } from 'react-query';
import { InvestmentTable } from '../InvestmentTable';
import { ColumnSelectionType } from '../InvestmentTable/columns';
import { TimeRange } from '../../types';
import { getStockPeers } from '../../apis/backend';
import Hr from '../Hr';
import SectionHeading from './SectionHeading';

interface Props extends HTMLProps<HTMLDivElement> {
  symbol: string;
}

const Related: FC<Props> = ({ symbol, ...props }) => {
  const query = useQuery(['investments', symbol, 'peers'], async () => getStockPeers(symbol));

  if (!query.data || query.data.length < 1) return <div />;

  return (
    <div {...props}>
      <Hr />
      <SectionHeading text="Related" />
      <InvestmentTable
        investments={query.data}
        columnSelections={[
          { type: ColumnSelectionType.Name },
          { type: ColumnSelectionType.Symbol },
          { type: ColumnSelectionType.ChangePercent, timeRange: TimeRange.months1 },
        ]}
        sortingRule={{ id: 'name' }}
      />
    </div>
  );
};

export default Related;
