import { isNil } from 'lodash';
import formatDisplay, { FormatterStyle } from '../../../utils/formatDisplay';
import { Stats } from '../../../apis/backend';

enum ArrowType {
  alwaysNeutral,
  higherIsBetter,
  lowerIsBetter,
}

const calculateMultiplier = (value1: number, value2: number) => value1 / value2;

const calculateRatio = (value1: number, value2: number) => Math.abs((value1 - value2) / value2);

const determineType = (value: number, comparisonValue: number, ratio: number, arrowType: ArrowType) => {
  if (ratio < 0.05 || arrowType === ArrowType.alwaysNeutral) {
    return 'NEUTRAL';
  }
  if (
    (arrowType === ArrowType.higherIsBetter && value > comparisonValue) ||
    (arrowType === ArrowType.lowerIsBetter && value < comparisonValue)
  ) {
    return 'POSITIVE';
  }
  return 'NEGATIVE';
};

export default (data: Stats, comparisonData: any) => {
  const stats = [
    {
      id: 'REVENUE',
      name: 'Revenue',
      section: 'Profits ($)',
      value: data.revenue,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      url: '/guide/revenue',
    },
    {
      id: 'EBITDA',
      name: 'EBITDA',
      section: 'Profits ($)',
      value: data.ebitda1y,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      url: '/guide/earnings',
    },
    {
      id: 'EARNINGS',
      name: 'Earnings',
      section: 'Profits ($)',
      value: data.earnings,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      url: '/guide/earnings',
    },
    {
      id: 'FREE_CASH_FLOW',
      name: 'Free Cash Flow',
      section: 'Profits ($)',
      value: data.freeCashFlow,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      url: '/guide/free-cash-flow',
    },
    {
      id: 'GROSS_MARGIN',
      name: 'Gross Margin',
      section: 'Profits (%)',
      value: data.grossMargin,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/gross-margin',
    },
    {
      id: 'PROFIT_MARGIN',
      name: 'Net Margin',
      section: 'Profits (%)',
      value: data.netMargin,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/net-margin',
    },
    {
      id: 'OP_CASH_FLOW_MARGIN',
      name: 'Operating Cashflow Margin',
      section: 'Profits (%)',
      value: data.operatingCashflowMargin1y,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/cash-flow-margin',
    },
    {
      id: 'FREE_CASH_FLOW_MARGIN',
      name: 'Free Cashflow Margin',
      section: 'Profits (%)',
      value: data.freeCashflowMargin,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/cash-flow-margin',
    },
    {
      id: 'RD_MARGIN',
      name: 'R&D % of Spend',
      section: 'Profits (%)',
      value: data.rdMargin1y,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.lowerIsBetter,
      //url: '/guide/gross-margin',
    },
    {
      id: 'SALES_MARGIN',
      name: 'Marketing % of Spend',
      section: 'Profits (%)',
      value: data.salesMargin1y,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.lowerIsBetter,
      //url: '/guide/gross-margin',
    },
    {
      id: 'REVENUE_GROWTH',
      name: isNil(data.revenueGrowth) ? 'Revenue Growth (3Y)' : 'Revenue Growth (1Y)',
      section: 'Growth',
      value: isNil(data.revenueGrowth) ? data.threeYearRevenueGrowth : data.revenueGrowth,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/revenue-growth',
    },
    {
      id: 'INCOME_GROWTH',
      name: isNil(data.earningsGrowth) ? 'Earnings Growth (3Y)' : 'Earnings Growth (1Y)',
      section: 'Growth',
      value: isNil(data.earningsGrowth) ? data.threeYearEarningsGrowth : data.earningsGrowth,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/earnings-growth',
    },
    {
      id: 'RD_SPEND',
      name: 'R&D Spend',
      section: 'Growth',
      value: data.rdSpend,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      url: '/guide/rd-spend',
    },
    {
      id: 'PRICE_TO_EARNINGS',
      name: 'Price / Earnings',
      section: 'Price',
      value: data.priceToEarnings,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.lowerIsBetter,
      url: '/guide/price-to-earnings',
    },
    {
      id: 'PRICE_TO_BOOK',
      name: 'Price / Book',
      section: 'Price',
      value: data.priceToBook,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.lowerIsBetter,
      url: '/guide/price-to-book',
    },
    {
      id: 'PRICE_TO_SALES',
      name: 'Price / Sales',
      section: 'Price',
      value: data.priceToSales,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.lowerIsBetter,
      url: '/guide/price-to-sales',
    },
    {
      id: 'PRICE_TO_EARNINGS_GROWTH',
      name: 'PEG',
      section: 'Price',
      value: data.priceToEarningsGrowth,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.lowerIsBetter,
      // url: '/guide/price-to-sales',
    },
    {
      id: 'EV_TO_EBITDA',
      name: 'EV / EBITDA',
      section: 'Price',
      value: data.evToEbitda,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.lowerIsBetter,
      // url: '/guide/price-to-sales',
    },
    {
      id: 'EV_TO_FREE_CASH_FLOW',
      name: 'EV / Free Cash Flow',
      section: 'Price',
      value: data.evToCashflow,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.lowerIsBetter,
      // url: '/guide/price-to-sales',
    },
    {
      id: 'EV_TO_GROSS_PROFIT',
      name: 'EV / Gross Profit',
      section: 'Price',
      value: data.evToGrossProfit,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.lowerIsBetter,
      // url: '/guide/price-to-sales',
    },
    {
      id: 'EV_TO_SALES',
      name: 'EV / Sales',
      section: 'Price',
      value: data.evToSales,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.lowerIsBetter,
      // url: '/guide/price-to-sales',
    },
    {
      id: 'CASH_FLOW_YIELD',
      name: 'Cashflow Yield',
      section: 'Price',
      value: data.cashflowYield,
      valueType: FormatterStyle.percentDetailed,
      arrowType: ArrowType.higherIsBetter,
      // url: '/guide/price-to-sales',
    },
    {
      id: 'EARNINGS_YIELD',
      name: 'Earnings Yield',
      section: 'Price',
      value: data.earningsYield,
      valueType: FormatterStyle.percentDetailed,
      arrowType: ArrowType.higherIsBetter,
      // url: '/guide/price-to-sales',
    },
    {
      id: 'DIVIDEND_YIELD',
      name: 'Dividend Yield',
      section: 'Price',
      value: data.dividendYield,
      valueType: FormatterStyle.percentDetailed,
      arrowType: ArrowType.higherIsBetter,
      // url: '/guide/market-cap',
    },
    {
      id: 'ENTERPRISE_VALUE',
      name: 'Enterprise Value (EV)',
      section: 'Share Info',
      value: data.enterpriseValue,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      url: '/guide/enterprise-value',
    },
    {
      id: 'MARKET_CAP',
      name: 'Market Cap',
      section: 'Share Info',
      value: data.marketCap,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      url: '/guide/market-cap',
    },
    {
      id: 'SHARE_GROWTH_TTM',
      name: 'Share Growth (1y)',
      section: 'Share Info',
      value: data.shareGrowth1q,
      valueType: FormatterStyle.percentChange,
      arrowType: ArrowType.lowerIsBetter,
      // url: '/guide/market-cap',
    },
    {
      id: 'SHARE_GROWTH_QQ',
      name: 'Share Growth Q/Q',
      section: 'Share Info',
      value: data.shareGrowthQq,
      valueType: FormatterStyle.percentChange,
      arrowType: ArrowType.lowerIsBetter,
      // url: '/guide/market-cap',
    },
    {
      id: 'Beta',
      name: 'Beta',
      section: 'Share Info',
      value: data.beta,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/market-cap',
    },
    {
      id: 'SHORT_PERCENT',
      name: 'Short Percent',
      section: 'Share Info',
      value: data.shortPercent,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/market-cap',
    },
    {
      id: 'INSIDER_BUYS',
      name: 'Insider Buys',
      section: 'Insider Activity (3mo)',
      value: data.dollarsBought,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/market-cap',
    },
    {
      id: 'INSIDER_SELLS',
      name: 'Insider Sells',
      section: 'Insider Activity (3mo)',
      value: data.dollarsSold,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/market-cap',
    },
    {
      id: 'INSIDER_EXERCISED',
      name: 'Insider Exercises',
      section: 'Insider Activity (3mo)',
      value: data.dollarsExercised,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/market-cap',
    },
    {
      id: 'INSIDER_GRANTS',
      name: 'Insider Grants',
      section: 'Insider Activity (3mo)',
      value: data.dollarsGranted,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/market-cap',
    },
    {
      id: 'INSIDER_OTHER',
      name: 'Other',
      section: 'Insider Activity (3mo)',
      value: data.dollarsOther,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/market-cap',
    },
    {
      id: 'INSIDER_TOTAL',
      name: 'Total Bought or Sold',
      section: 'Insider Activity (3mo)',
      value: data.netDollarsTraded,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/market-cap',
    },
    {
      id: 'INSIDER_PERCENT',
      name: '% of Market Cap Bought or Sold',
      section: 'Insider Activity (3mo)',
      value: data.netPercentTraded,
      valueType: FormatterStyle.percentDetailed2,
      arrowType: ArrowType.higherIsBetter,
      // url: '/guide/market-cap',
    },
    {
      id: 'SALES_ON_CAPITAL',
      name: 'Sales on Capital',
      section: 'Efficiency',
      value: data.salesOnCapital,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/return-on-capital',
    },
    {
      id: 'RETURN_ON_EQUITY',
      name: 'Return on Equity',
      section: 'Efficiency',
      value: data.returnOnEquity,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      // url: '/guide/return-on-equity',
    },
    {
      id: 'RETURN_ON_ASSETS',
      name: 'Return on Assets',
      section: 'Efficiency',
      value: data.returnOnAssets,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      // url: '/guide/return-on-assets',
    },
    {
      id: 'RETURN_ON_CAPITAL',
      name: 'Return on Capital',
      section: 'Efficiency',
      value: data.returnOnCapital,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/return-on-capital',
    },
    {
      id: 'CASHFLOW_RETURN_ON_CAPITAL',
      name: 'Cashflow Return on Capital',
      section: 'Efficiency',
      value: data.cashflowReturnOnCapital,
      valueType: FormatterStyle.percent,
      arrowType: ArrowType.higherIsBetter,
      url: '/guide/return-on-capital',
    },
    {
      id: 'CASH',
      name: 'Cash',
      section: 'Cash Position',
      value: data.cash,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/cash',
    },
    {
      id: 'DEBT',
      name: 'Debt',
      section: 'Cash Position',
      value: data.debt,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
      // url: '/guide/debt',
    },
    {
      id: 'TOTAL_CAPITAL',
      name: 'Capital',
      section: 'Cash Position',
      value: data.totalCapital,
      valueType: FormatterStyle.moneyBig,
      arrowType: ArrowType.alwaysNeutral,
    },
    {
      id: 'CASH_TO_DEBT',
      name: 'Cash / Debt Ratio',
      section: 'Cash Position',
      value: data.cashToDebt,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.higherIsBetter,
      // url: '/guide/cash-to-debt',
    },
    {
      id: 'EQUITY_TO_DEBT',
      name: 'Equity / Debt Ratio',
      section: 'Cash Position',
      value: data.equityToDebt,
      valueType: FormatterStyle.number,
      arrowType: ArrowType.higherIsBetter,
      // url: '/guide/equity-to-debt',
    },
  ];

  return stats.map(({ id, name, section, value, valueType, arrowType, url }) => {
    const comparisonValue = comparisonData[id];

    if (isNil(value)) {
      return {
        id,
        name,
        section,
        type: arrowType === ArrowType.lowerIsBetter ? 'NEGATIVE' : 'NEUTRAL',
        value,
        displayValue: '—',
        comparisonValue,
        displayComparisonValue: formatDisplay(valueType, comparisonValue),
        url,
        multiplier: arrowType === ArrowType.lowerIsBetter ? 0.01 : 0,
      };
    }

    const ratio = calculateRatio(value, comparisonValue);
    const multiplier = calculateMultiplier(value, comparisonValue);

    return {
      id,
      name,
      section,
      type: determineType(value, comparisonValue, ratio, arrowType),
      value,
      displayValue: formatDisplay(valueType, value),
      comparisonValue,
      displayComparisonValue: formatDisplay(valueType, comparisonValue),
      url,
      multiplier,
    };
  });
};
