import { css, ClassNames } from '@emotion/react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { buildPageSpacing, colors, darkmodeStyles, fontStyles, spacing } from '../../styles';
import { General } from '../../stores';
import exploreIcon from './magnifyingGlassInactive.svg';
import exploreIconActive from './magnifyingGlassActive.svg';
import briefcaseIconInactive from './briefcaseInactive.svg';
import briefcaseIconActive from './briefcaseActive.svg';
import dotsActive from './dotsActive.svg';
import dotsInactive from './dotsInactive.svg';
import newsIcon from './newsInactive.svg';
import newsIconActive from './newsActive.svg';
import chatIcon from './chatInactive.svg';
import chatIconActive from './chatActive.svg';

const NavigationBar = () => {
  const isKeyboardVisible = General.useState((s) => s.isKeyboardVisible);
  const history = useHistory();

  const navigationBarStyles = css`
    z-index: 101;
    background-color: ${colors.sugar};
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    height: ${buildPageSpacing(spacing.giga, 'BOTTOM')};
    padding-bottom: ${buildPageSpacing('0px', 'BOTTOM')};
    display: ${isKeyboardVisible ? 'none' : 'flex'};
    border-top: 1px solid rgba(136, 133, 129, 0.25);
    ${darkmodeStyles.regular}
  `;

  return (
    <div css={navigationBarStyles}>
      <ClassNames>
        {({ css }) => {
          const styles = {
            link: css`
              flex: 1;
              ${fontStyles.flea};
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              color: ${colors.silver};
              html[data-theme='dark'] & {
                background-color: ${colors.liquorice};
              }
            `,
            activeLink: css`
              color: ${colors.petrolBlue};
              html[data-theme='dark'] & {
                color: ${colors.petrolBlue};
              }
            `,
            icon: css`
              height: 22px;
              margin-bottom: ${spacing.milli};
            `,
          };
          return (
            <>
              <NavLink className={styles.link} activeClassName={styles.activeLink} exact to="/portfolio">
                <img
                  src={history.location.pathname === '/portfolio' ? briefcaseIconActive : briefcaseIconInactive}
                  className={styles.icon}
                />
                <span>Watchlist</span>
              </NavLink>
              <NavLink className={styles.link} activeClassName={styles.activeLink} exact to="/search">
                <img
                  src={history.location.pathname === '/search' ? exploreIconActive : exploreIcon}
                  className={styles.icon}
                />
                <span>Discover</span>
              </NavLink>
              <NavLink className={styles.link} activeClassName={styles.activeLink} exact to="/news">
                <img src={history.location.pathname === '/news' ? newsIconActive : newsIcon} className={styles.icon} />
                <span>News</span>
              </NavLink>
              <NavLink className={styles.link} activeClassName={styles.activeLink} exact to="/chat">
                <img src={history.location.pathname === '/chat' ? chatIconActive : chatIcon} className={styles.icon} />
                <span>Chat</span>
              </NavLink>
              <NavLink className={styles.link} activeClassName={styles.activeLink} exact to="/more">
                <img src={history.location.pathname === '/more' ? dotsActive : dotsInactive} className={styles.icon} />
                <span>More</span>
              </NavLink>
            </>
          );
        }}
      </ClassNames>
    </div>
  );
};

export default NavigationBar;
