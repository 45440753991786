import { Link, LinkProps } from 'react-router-dom';

const ConditionalLink = ({ to, replace, innerRef, ...htmlProps }: LinkProps) => {
  if (!to || !(to as any).pathname) {
    return <span {...(htmlProps as any)} />;
  }
  const props = { to, replace, innerRef, ...htmlProps };

  return <Link {...props} />;
};

export default ConditionalLink;
