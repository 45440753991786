import * as lightcollectionImages from '../assets/collectionImages';
import * as darkCollectionImages from '../assets/collectionImages/dark';
import * as lightSubscriptionImages from '../assets/subscriptionImages';
import * as darkSubscriptionImages from '../assets/subscriptionImages/dark';

export const getCollectionImages = (id?: string): string | undefined => {
  if (!id) return undefined;

  const isDarkMode = document.documentElement.getAttribute('data-theme') === 'dark';

  return isDarkMode ? (darkCollectionImages as any)[id] : (lightcollectionImages as any)[id];
};

export const getSubscriptionTakeoverImages = () => {
  const isDarkMode = document.documentElement.getAttribute('data-theme') === 'dark';

  return isDarkMode ? darkSubscriptionImages : lightSubscriptionImages;
};

const themeObserver = new MutationObserver(() => {
  window.dispatchEvent(new CustomEvent('themeChanged'));
});

themeObserver.observe(document.documentElement, {
  attributes: true,
  attributeFilter: ['data-theme'],
});
