import { css } from '@emotion/react';
import { FC, useState } from 'react';
import SectionHeading from '../SectionHeading';
import Takeover from '../../Takeover';
import Button from '../../Button';
import { spacing, fontStyles, colors } from '../../../styles';
import Hr from '../../Hr';
import externalLink from '../../../assets/externalLink.svg';

interface Props {
  symbol: string;
  category: string;
  description: string;
  website?: string;
  investorRelationsUrl?: string;
  industry?: string;
  sector?: string;
  ceo?: string;
  employees?: number;
  headquarters?: string;
}

const styles = {
  table: css`
    border-collapse: collapse;
    width: 100%;
  `,
  cell: css`
    width: 50%;
    vertical-align: text-top;
    padding: 0 0 ${spacing.centi} 0;
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `,
  label: css`
    text-align: left;
    color: ${colors.tungsten};
    ${fontStyles.cat}
  `,
  value: css`
    text-align: right;
    ${fontStyles.roboCat}
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `,
  shortenedDescription: css`
    ${fontStyles.cat}
    margin: 0 0 ${spacing.regular} 0;
    html[data-theme='dark'] & {
      color: ${colors.sugar};
    }
  `,
  externalLink: css`
    display: block;
    margin-bottom: ${spacing.regular};
    ${fontStyles.roboCat}
    html[data-theme='dark'] & {
      color: ${colors.skyBlue};
    }
  `,
  externalLinkIcon: css`
    vertical-align: text-bottom;
  `,
};

const ExternalLink: FC<{ website?: string; investorRelationsUrl?: string }> = ({ website, investorRelationsUrl }) => {
  if (!website && !investorRelationsUrl) return null;
  if (investorRelationsUrl)
    return (
      <a href={investorRelationsUrl} css={styles.externalLink}>
        Investor relations <img src={externalLink} css={styles.externalLinkIcon} />
      </a>
    );

  return (
    <a href={website} css={styles.externalLink}>
      Website
      <img src={externalLink} css={styles.externalLinkIcon} />
    </a>
  );
};

const shortenDescription = (description: string) => {
  const shortened = description.split(' ').slice(0, 20).join(' ');
  return shortened.match(/[.,:!?]$/g) ? shortened.slice(0, -1) : shortened;
};

const Info: FC<Props> = ({
  symbol,
  category,
  description,
  website,
  investorRelationsUrl,
  industry,
  sector,
  ceo,
  employees,
  headquarters,
}) => {
  const [isDescriptionShown, setIsDescriptionShown] = useState<boolean>(false);
  const labelToValueMapping = {
    Industry: industry,
    Sector: sector,
    CEO: ceo,
    'Number of employees': employees?.toLocaleString(),
    Headquarters: headquarters,
  };

  const tableContents = Object.entries(labelToValueMapping).filter(([, value]) => !!value);

  if (!description && !website && !investorRelationsUrl && tableContents.length === 0) return null;

  return (
    <div>
      <Hr />
      <SectionHeading text={['STOCK', 'ADR'].includes(category) ? 'Company Info' : 'Fund Info'} />
      {description ? (
        <p css={styles.shortenedDescription}>
          {shortenDescription(description)}
          {shortenDescription(description) !== description && (
            <>
              ...{' '}
              <Button
                onClick={() => setIsDescriptionShown(true)}
                css={css`
                  ${fontStyles.mouse}
                `}
              >
                Read more
              </Button>
            </>
          )}
        </p>
      ) : null}
      <Takeover
        isShown={isDescriptionShown}
        onClose={() => setIsDescriptionShown(false)}
        title={`About ${symbol}`}
        name="AboutInvestment"
      >
        <p
          css={css`
            ${fontStyles.cat}
          `}
        >
          {description}
        </p>
      </Takeover>
      <ExternalLink website={website} investorRelationsUrl={investorRelationsUrl} />
      <table css={styles.table}>
        <tbody>
          {tableContents.map(([label, value]) => (
            <tr key={label}>
              <th css={[styles.cell, styles.label]}>{label}</th>
              <td css={[styles.cell, styles.value]}>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Info;
