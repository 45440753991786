import { css } from '@emotion/react';
import { HTMLProps, FC } from 'react';
import { borderRadius, colors, spacing } from '../../styles';

type Props = HTMLProps<HTMLDivElement>;

/**
 * Card should be a clickable component, unlike Box.
 */
const Card: FC<Props> = ({ children, ...props }) => {
  const styles = {
    card: css`
      display: inline-block;
      background-color: ${colors.sugar};
      border-radius: ${borderRadius};
      padding: ${spacing.deci};
      text-decoration: none;
      color: ${colors.tungsten};
      border: 2px solid ${colors.cloud};
      box-shadow: 0px 2px 3px 0px rgb(203, 202, 201, 0.5);

      html[data-theme='dark'] & {
        background-color: ${colors.liquorice};
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
      }
    `,
  };

  return (
    <div css={styles.card} {...props}>
      {children}
    </div>
  );
};

export default Card;
