import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import * as stores from '../../stores';
import { useInvestmentsCacheState } from '../../utils/persistedState';
import Button from '../Button';
import { spacing, fontStyles } from '../../styles';
import bookmarkActiveIcon from './bookmarkFull.svg';
import bookmarkInactiveIcon from './bookmarkEmpty.svg';

interface Props {
  symbol: string;
  category: string;
  name: string;
  showLabel?: boolean;
}

const BookmarkButton = ({ symbol, category, name, showLabel = false }: Props) => {
  const styles = {
    bookmarkButton: css`
      padding: ${spacing.centi};
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0px;

      &:focus {
        outline: none;
      }
    `,
    bookmarkIcon: css`
      height: 22px;
      vertical-align: middle;
    `,
    label: css`
      ${fontStyles.mouse}
    `,
  };

  const watchlist = stores.General.useState((s) => s.watchlist);
  const [investmentsCache, setInvestmentsCache] = useInvestmentsCacheState();
  const isBookmarked = !!watchlist[symbol];
  const onClick = (symbol: string): void => {
    if (toast.isActive('bookmark')) {
      toast.update('bookmark', { render: isBookmarked ? 'Removed from watchlist' : 'Added to watchlist' });
    } else {
      toast(isBookmarked ? 'Removed from watchlist' : 'Added to watchlist', { toastId: 'bookmark' });
    }

    stores.General.update((s) => {
      if (isBookmarked) {
        // Remove from watchlist
        const newWatchlist = { ...s.watchlist };
        delete newWatchlist[symbol];
        s.watchlist = newWatchlist;
      } else {
        // Add to watchlist
        s.watchlist[symbol] = true;
      }
    });

    setInvestmentsCache({ ...investmentsCache, [symbol]: { symbol, category, name } });
  };

  return (
    <Button type="button" onClick={() => onClick(symbol)} css={styles.bookmarkButton}>
      <img src={isBookmarked ? bookmarkActiveIcon : bookmarkInactiveIcon} css={styles.bookmarkIcon} alt="Bookmark" />
      {showLabel && <span css={styles.label}>{isBookmarked ? 'Remove' : 'Add'}</span>}
    </Button>
  );
};

export default BookmarkButton;
