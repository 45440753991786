import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { Spinner } from '@chakra-ui/react';
import { useState, FC } from 'react';
import { useMutation } from 'react-query';
import Takeover, { Props } from '../Takeover';
import { colors, spacing, fontStyles } from '../../styles';
import Button from '../Button';
import { sendFeedback } from '../../apis/backend';

const FeedbackTakeover: FC<Props> = ({ onClose, ...props }) => {
  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const sendFeedbackMutation = useMutation(async (data: { text: string; email?: string }) => sendFeedback(data));

  const labelStyle = css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${spacing.deci};
    ${fontStyles.cat}

    div {
      margin: 0 0 ${spacing.centi} 0;
    }
  `;

  const handleClose = () => {
    onClose();
    setText('');
    setEmail('');
  };

  return (
    <Takeover {...props} onClose={handleClose} title="Give feedback directly" name="Give feedback">
      <form
        noValidate
        css={css`
          margin: 0;
          display: flex;
          flex-direction: column;
          height: 100%;
        `}
        onSubmit={(event) => {
          sendFeedbackMutation.mutate(
            { text, email },
            {
              onSuccess: () => {
                handleClose();
                toast('Feedback sent — thanks for sharing!');
              },
              onError: () => {
                toast('There was an error during submission, please try again.');
              },
            },
          );
          event.preventDefault();
        }}
      >
        <label css={labelStyle}>
          <div>Email (optional)</div>
          <input
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            placeholder="example@example.com"
            css={css`
              width: 100%;
              margin-bottom: ${spacing.centi};
            `}
          />
        </label>
        <label
          css={css`
            ${labelStyle}
            flex: 1;
          `}
        >
          <div>Feedback</div>
          <textarea
            placeholder="Here are my thoughts on Bloom..."
            value={text}
            onChange={(event) => setText(event.target.value)}
            css={css`
              resize: none;
              width: 100%;
              flex: 1;
              html[data-theme='dark'] & {
                background-color: ${colors.liquorice};
                color: ${colors.sugar};
              }
            `}
          />
        </label>
        <div
          css={css`
            text-align: right;
          `}
        >
          <Button
            type="submit"
            disabled={!text || sendFeedbackMutation.isLoading}
            css={css`
              ${fontStyles.mouse}
              padding: ${spacing.regular} 0 ${spacing.regular} ${spacing.regular};
            `}
          >
            {sendFeedbackMutation.isLoading ? <Spinner color={colors.cloud} size="sm" /> : 'Submit feedback'}
          </Button>
        </div>
      </form>
    </Takeover>
  );
};

export default FeedbackTakeover;
