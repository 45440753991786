import createPersistedState from 'use-persisted-state';

export type InvestmentCache = {
  [symbol: string]: {
    symbol: string;
    category: string;
    name: string;
  };
} | null;

export const useInvestmentsCacheState = createPersistedState<InvestmentCache>('investmentsCache');
