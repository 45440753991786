import { css } from '@emotion/react';
import { useEffect, useState, FC } from 'react';
import SectionHeading from '../SectionHeading';
import HoldingSymbolCard from '../../HoldingSymbolCard';
import Hr from '../../Hr';
import { spacing } from '../../../styles';
import { reportError } from '../../../utils/reporting';
import { getHoldings, Holding } from '../../../apis/backend';

interface Props {
  symbol: string;
}

const styles = {
  card: css`
    margin-bottom: ${spacing.centi};
  `,
};

const Holdings: FC<Props> = ({ symbol }) => {
  const [holdings, setHoldings] = useState<Holding[]>();
  useEffect(() => {
    (async () => {
      try {
        setHoldings(await getHoldings(symbol));
      } catch (e) {
        if (e instanceof Error) {
          reportError(e);
        } else {
          reportError(new Error(String(e)));
        }
      }
    })();
  }, [symbol]);

  if (!holdings || holdings.length < 1) return <div />;
  return (
    <div>
      <Hr />
      <SectionHeading text="Holdings" />
      {holdings.map((holding: any, index: any) => (
        <HoldingSymbolCard key={holding.symbol || index} css={styles.card} {...holding} />
      ))}
    </div>
  );
};

export default Holdings;
