import { css } from '@emotion/react';
import { ComponentPropsWithoutRef, FC } from 'react';
import { buildPageSpacing, colors, darkmodeStyles, spacing } from '../../styles';
import NavigationBar from '../NavigationBar';
import { General } from '../../stores';

interface Props extends ComponentPropsWithoutRef<'div'> {
  showNavigationBar?: boolean;
}

const Page: FC<Props> = ({ children, showNavigationBar, ...props }) => {
  const isKeyboardVisible = General.useState((s) => s.isKeyboardVisible);

  return (
    <div
      css={css`
        padding: ${buildPageSpacing(spacing.regular, 'TOP')} ${buildPageSpacing(spacing.deci, 'RIGHT')}
          calc(
            ${showNavigationBar && !isKeyboardVisible ? spacing.giga : '0px'} +
              ${buildPageSpacing(spacing.regular, 'BOTTOM')}
          )
          ${buildPageSpacing(spacing.deci, 'LEFT')};
      `}
      {...props}
    >
      <div
        css={css`
          background-color: ${colors.sugar};
          z-index: 100;
          height: env(safe-area-inset-top);
          width: 100vw;
          position: fixed;
          top: 0;
          left: 0;
          ${darkmodeStyles.regular}
        `}
      />
      {children}
      {showNavigationBar && <NavigationBar />}
    </div>
  );
};

export default Page;
