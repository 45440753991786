import { css } from '@emotion/react';
import { FC } from 'react';
import { toast } from 'react-toastify';
import { colors, darkmodeStyles, fontStyles, spacing } from '../../styles';
import * as stores from '../../stores';

interface Props {
  investments: Array<{ symbol: string; name: string }>;
  onResultClick?: (symbol: string, name: string) => void;
  isError?: boolean;
}

const Results: FC<Props> = ({ investments = [], onResultClick, isError }) => {
  const watchlist = stores.General.useState((s) => s.watchlist);

  const handleClick = (symbol: string, name: string) => {
    const isCurrentlySelected = !!watchlist[symbol];

    if (isCurrentlySelected) {
      stores.General.update((s) => {
        const newWatchlist = { ...s.watchlist };
        delete newWatchlist[symbol];
        s.watchlist = newWatchlist;
      });
      toast('Removed from watchlist');
    } else {
      if (onResultClick) {
        onResultClick(symbol, name);
      }
      toast('Added to watchlist');
    }
  };

  return (
    <div
      css={css`
        background-color: ${colors.sugar};
        border-top: none;
        border-radius: 0 0 8px 8px;
        padding: ${spacing.regular};
        ${darkmodeStyles.regular}
      `}
    >
      {(() => {
        if (isError)
          return (
            <div
              css={css`
                padding: ${spacing.centi};
              `}
            >
              Problem fetching results.
            </div>
          );
        if (investments.length === 0)
          return (
            <div
              css={css`
                padding: ${spacing.centi};
              `}
            >
              No results
            </div>
          );

        return (
          <div
            css={css`
              display: flex;
              flex-wrap: wrap;
              gap: ${spacing.centi};
              justify-content: center;
            `}
          >
            {investments.map(({ symbol, name }) => {
              const isSelected = !!watchlist[symbol];

              return (
                <label
                  key={symbol}
                  css={css`
                    ${fontStyles.dog};
                    padding: ${spacing.centi} ${spacing.deci};
                    display: block;
                    color: ${isSelected ? colors.sugar : colors.liquorice};
                    text-align: center;
                    background-color: ${isSelected ? colors.petrolBlue : 'transparent'};
                    border: 1px solid ${isSelected ? colors.petrolBlue : colors.tungsten};
                    border-radius: 20px;
                    transition: all 0.2s ease;
                    cursor: pointer;
                    user-select: none;
                    -webkit-tap-highlight-color: transparent;

                    html[data-theme='dark'] & {
                      color: ${colors.sugar};
                      border-color: ${isSelected ? colors.petrolBlue : colors.tungsten};
                      background-color: ${isSelected ? colors.petrolBlue : colors.tungsten};
                    }
                  `}
                >
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => handleClick(symbol, name)}
                    css={css`
                      position: absolute;
                      opacity: 0;
                      cursor: pointer;
                      height: 0;
                      width: 0;
                    `}
                  />
                  <div
                    css={css`
                      ${fontStyles.lion};
                    `}
                  >
                    {symbol}
                  </div>
                </label>
              );
            })}
          </div>
        );
      })()}
    </div>
  );
};

export default Results;
