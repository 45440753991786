import { FC, useEffect, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { formatDistanceToNowStrict, sub } from 'date-fns';
import { Spinner } from '@chakra-ui/react';
import { colors, spacing, fontStyles } from '../../styles';
import Page from '../Page';
import * as stores from '../../stores';
import { getWiims, getBasicInvestments, BasicInvestment } from '../../apis/backend';
import lock from '../CollectionCard/lock.svg';
import ColoredPercentChange from '../ColoredPercentChange';
import { TimeRange } from '../../types';

interface NewsItem {
  title: string;
  symbol: string;
  created: string;
  changePercent?: number;
}

const NewsPage: FC = () => {
  const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const watchlist = stores.General.useState((s) => s.watchlist);
  const watchlistSymbols = useMemo(() => Object.keys(watchlist || {}), [watchlist]);
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const [investmentData, setInvestmentData] = useState<Record<string, BasicInvestment>>({});
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  const isNewsItemLocked = (created: string): boolean => {
    const twoDaysAgo = sub(new Date(), { days: 2 });
    return new Date(created) < twoDaysAgo;
  };

  const handleLockedItemClick = () => {
    stores.General.update((s) => {
      s.isSubscriptionTakeoverShown = true;
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        // Get WIIMs with watchlist symbols if they exist, otherwise get all WIIMs
        const newsResponse = await getWiims(watchlistSymbols.length > 0 ? watchlistSymbols : []);

        if (!isMounted) return;

        // Extract unique symbols from the news response
        const newsSymbols = Array.from(new Set(Object.keys(newsResponse)));

        // Get investment data for all symbols that appear in news
        const investmentResponse = await getBasicInvestments(newsSymbols);

        const formattedNews: NewsItem[] = [];
        Object.entries(newsResponse).forEach(([symbol, items]) => {
          items.forEach((item) => {
            formattedNews.push({
              ...item,
              symbol,
            });
          });
        });

        formattedNews.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

        if (isMounted) {
          setNewsItems(formattedNews);
          setInvestmentData(
            investmentResponse.reduce(
              (acc, item) => ({
                ...acc,
                [item.symbol]: item,
              }),
              {},
            ),
          );
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [watchlistSymbols]);

  // Helper function to get the appropriate change percent based on article date
  const getChangePercent = (created: string, symbol: string): number | undefined => {
    const investment = investmentData[symbol];
    if (!investment) return undefined;

    const articleDate = new Date(created);
    const now = new Date();
    const daysDiff = Math.floor((now.getTime() - articleDate.getTime()) / (1000 * 60 * 60 * 24));

    if (daysDiff <= 1) return investment.changePercent1d;
    if (daysDiff <= 7) return investment.changePercent1w;
    if (daysDiff <= 30) return investment.changePercent1m;
    if (daysDiff <= 90) return investment.changePercent3m;
    return investment.changePercent1y;
  };

  const getTimeRange = (created: string): TimeRange => {
    const daysDiff = Math.floor((new Date().getTime() - new Date(created).getTime()) / (1000 * 60 * 60 * 24));
    if (daysDiff <= 1) return TimeRange.days1;
    if (daysDiff <= 7) return TimeRange.weeks1;
    if (daysDiff <= 30) return TimeRange.months1;
    if (daysDiff <= 90) return TimeRange.months3;
    return TimeRange.years1;
  };

  return (
    <Page showNavigationBar>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: ${spacing.milli};
        `}
      >
        <h1
          css={css`
            color: ${colors.liquorice};
            margin: 0 0 ${spacing.centi} 0;
            ${fontStyles.shark}
            html[data-theme='dark'] & {
              color: ${colors.sugar};
            }
          `}
        >
          News
        </h1>
        {isSubscribed && (
          <div
            css={css`
              background: ${colors.petrolBlue};
              color: ${colors.sugar};
              padding: 2px ${spacing.milli};
              border-radius: 4px;
              ${fontStyles.flea}
              text-transform: uppercase;
              letter-spacing: 0.5px;
              margin-bottom: ${spacing.centi};
              html[data-theme='dark'] & {
                background: ${colors.skyBlue};
              }
            `}
          >
            Pro
          </div>
        )}
      </div>

      {!isSubscribed && (
        <p
          css={css`
            ${fontStyles.dog}
            color: ${colors.silver};
            text-align: left;
            padding-top: ${spacing.centi};
          `}
        >
          News older than 48 hours requires a subscription to view.{' '}
          <Link
            to="#"
            onClick={() => {
              stores.General.update((s) => {
                s.isSubscriptionTakeoverShown = true;
              });
            }}
            css={css`
              color: ${colors.petrolBlue};
              text-decoration: none;
              html[data-theme='dark'] & {
                color: ${colors.skyBlue};
              }
            `}
          >
            Click here to subscribe
          </Link>
          .
        </p>
      )}

      {isLoading ? (
        <div
          css={css`
            display: flex;
            justify-content: center;
            margin-top: ${spacing.centi};
          `}
        >
          <Spinner color={colors.cloud} size="lg" />
        </div>
      ) : newsItems.length === 0 ? (
        <p
          css={css`
            ${fontStyles.dog}
            color: ${colors.tungsten};
            text-align: center;
            margin-top: ${spacing.centi};
          `}
        >
          No news available
        </p>
      ) : (
        <ul
          css={css`
            list-style: none;
            padding: 0;
            margin: 0;
          `}
        >
          {newsItems.map((item, index) => (
            <li
              key={`${item.symbol}-${index}`}
              css={css`
                padding: ${spacing.deci} 0;
                border-bottom: 1px solid ${colors.cloud};
                &:last-child {
                  border-bottom: none;
                }
                html[data-theme='dark'] & {
                  border-color: ${colors.tungsten};
                }
              `}
            >
              {isNewsItemLocked(item.created) && !isSubscribed ? (
                <div
                  onClick={handleLockedItemClick}
                  css={css`
                    text-decoration: none;
                    display: block;
                    cursor: pointer;
                    &:hover {
                      background-color: ${colors.fog};
                      html[data-theme='dark'] & {
                        background-color: ${colors.tungsten};
                      }
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: ${spacing.centi};
                      margin-bottom: ${spacing.milli};
                    `}
                  >
                    <span
                      css={css`
                        color: ${colors.sugar};
                        padding: ${spacing.milli} ${spacing.milli};
                        border-radius: 4px;
                        ${fontStyles.flea}
                        display: flex;
                        align-items: center;
                        gap: ${spacing.milli};
                      `}
                    >
                      <ColoredPercentChange
                        value={getChangePercent(item.created, item.symbol)}
                        timeRange={getTimeRange(item.created)}
                        prefix={item.symbol}
                      />
                      <div
                        css={css`
                          background: ${colors.petrolBlue};
                          color: ${colors.sugar};
                          padding: 2px ${spacing.milli};
                          border-radius: 4px;
                          ${fontStyles.flea}
                          text-transform: uppercase;
                          letter-spacing: 0.5px;
                        `}
                      >
                        <img
                          src={lock}
                          alt="Locked"
                          css={css`
                            width: 12px;
                            height: 12px;
                            filter: brightness(0) invert(1);
                            vertical-align: middle;
                            top: -1px;
                            margin-right: 1px;
                            position: relative;
                          `}
                        />
                        Pro
                      </div>
                    </span>
                    <span
                      css={css`
                        color: ${colors.silver};
                        ${fontStyles.flea}
                      `}
                    >
                      {formatDistanceToNowStrict(new Date(item.created))} ago
                    </span>
                  </div>
                  <div
                    css={css`
                      margin: 0;
                      ${fontStyles.dog}
                      color: ${colors.liquorice};
                      html[data-theme='dark'] & {
                        color: ${colors.sugar};
                      }
                    `}
                  >
                    <div
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;
                      `}
                    >
                      {/* Create multiple blocks to simulate text lines */}
                      {[...Array(3)].map((_, i) => (
                        <div
                          key={i}
                          css={css`
                            height: 1em;
                            width: ${i === 2 ? '60%' : '100%'};
                            background-color: ${colors.cloud};
                            border-radius: 4px;
                          `}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <Link
                  to={`/symbol/${item.symbol}`}
                  css={css`
                    text-decoration: none;
                    display: block;
                    &:hover {
                      background-color: ${colors.fog};
                      html[data-theme='dark'] & {
                        background-color: ${colors.tungsten};
                      }
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: ${spacing.centi};
                      margin-bottom: ${spacing.milli};
                    `}
                  >
                    <span
                      css={css`
                        color: ${colors.sugar};
                        padding: ${spacing.milli} ${spacing.milli};
                        border-radius: 4px;
                        ${fontStyles.flea}
                        display: flex;
                        align-items: center;
                        gap: ${spacing.milli};
                      `}
                    >
                      <ColoredPercentChange
                        value={getChangePercent(item.created, item.symbol)}
                        timeRange={getTimeRange(item.created)}
                        prefix={item.symbol}
                      />
                    </span>
                    {isNewsItemLocked(item.created) && (
                      <div
                        css={css`
                          background: ${colors.petrolBlue};
                          color: ${colors.sugar};
                          padding: 2px ${spacing.milli};
                          border-radius: 4px;
                          ${fontStyles.flea}
                          text-transform: uppercase;
                          letter-spacing: 0.5px;
                        `}
                      >
                        Pro
                      </div>
                    )}
                    <span
                      css={css`
                        color: ${colors.silver};
                        ${fontStyles.flea}
                      `}
                    >
                      {formatDistanceToNowStrict(new Date(item.created))} ago
                    </span>
                  </div>
                  <p
                    css={css`
                      margin: 0;
                      ${fontStyles.dog}
                      color: ${colors.liquorice};
                      html[data-theme='dark'] & {
                        color: ${colors.sugar};
                      }
                    `}
                  >
                    {item.title}
                  </p>
                </Link>
              )}
            </li>
          ))}
        </ul>
      )}
    </Page>
  );
};

export default NewsPage;
