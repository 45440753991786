import { FC, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { fontStyles, spacing } from '../../styles';
import arrowForward from './images/arrowForward.svg';

interface Props {
  description: string;
  lightImage: string;
  darkImage: string;
  redirectPath: string;
  onComplete?: () => void;
  onClick?: () => void;
}

const OnboardingSlide: FC<Props> = ({ description, lightImage, darkImage, redirectPath, onComplete, onClick }) => {
  const history = useHistory();
  const [currentImage, setCurrentImage] = useState(lightImage);

  useEffect(() => {
    const updateImage = () => {
      const isDark = document.documentElement.getAttribute('data-theme') === 'dark';
      setCurrentImage(isDark ? darkImage : lightImage);
    };

    updateImage();
    window.addEventListener('themeChanged', updateImage);
    return () => window.removeEventListener('themeChanged', updateImage);
  }, [lightImage, darkImage]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      if (onComplete) {
        onComplete();
      }
      history.push(redirectPath);
    }
  };

  return (
    <div
      onClick={handleClick}
      css={css`
        display: flex;
        align-items: center;
        gap: ${spacing.regular};
        width: 100%;
        cursor: pointer;
        transition: opacity 0.2s ease;

        &:hover {
          opacity: 0.8;
        }

        &:active {
          opacity: 0.6;
        }
      `}
    >
      <div
        css={css`
          flex: 0 0 auto;
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <img
          src={currentImage}
          title="Onboarding image"
          alt="Onboarding"
          css={css`
            width: 100%;
            height: 100%;
            object-fit: contain;
            pointer-events: none;
          `}
        />
      </div>

      <p
        css={css`
          flex: 1;
          ${fontStyles.cat};
          text-align: left;
          margin: 0;
          font-weight: 400;
          pointer-events: none;
        `}
      >
        {description}
      </p>

      <img
        src={arrowForward}
        css={css`
          pointer-events: none;
        `}
      />
    </div>
  );
};

export default OnboardingSlide;
