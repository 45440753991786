export default {
  REVENUE: 68080000000,
  GROSS_MARGIN: 0.48,
  PROFIT_MARGIN: 0.13,
  OPERATING_CASH_FLOW: 10900000000,
  EARNINGS: 8996000000,
  REVENUE_GROWTH: 0.07,
  INCOME_GROWTH: 0.12,
  RD_SPEND: 3492000000,
  PRICE_TO_EARNINGS: 22.47,
  PRICE_TO_BOOK: 3.1,
  PRICE_TO_SALES: 2.1,
  EV_TO_REVENUE: 3.2,
  EV_TO_EBITDA: 13.2,
  RETURN_ON_CAPITAL: 0.11,
  RETURN_ON_EQUITY: 0.14,
  RETURN_ON_ASSETS: 0.084,
  CASH_TO_DEBT: 1,
  EQUITY_TO_DEBT: 2,
  MARKET_CAP: 224000000000,
  EBITDA: 10892000000,
  ENTERPRISE_VALUE: 224000000000,
  DIVIDEND_YIELD: 0.02,
};
