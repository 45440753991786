import { Preferences } from '@capacitor/preferences';

const moveIsAppVisited = () => {
  if (window.localStorage.getItem('isAppVisited') !== null) return;

  const reduxStateString = window.localStorage.getItem('persist:root');
  if (reduxStateString === null) return;

  const reduxState = JSON.parse(reduxStateString);
  const persistedApp = JSON.parse(reduxState.persistedApp);

  if (persistedApp.isAppVisited) {
    window.localStorage.setItem('isAppVisited', 'true');
  }
};

const moveHasFeedbackBeenClosed = () => {
  if (window.localStorage.getItem('hasFeedbackBeenClosed') !== null) return;

  const reduxStateString = window.localStorage.getItem('persist:root');
  if (reduxStateString === null) return;

  const reduxState = JSON.parse(reduxStateString);
  const persistedApp = JSON.parse(reduxState.persistedApp);

  if (persistedApp.hasFeedbackBeenClosed) {
    window.localStorage.setItem('hasFeedbackBeenClosed', 'true');
  }
};

const moveWatchlist = () => {
  if (window.localStorage.getItem('watchlist') !== null) return;

  const reduxStateString = window.localStorage.getItem('persist:root');
  if (reduxStateString === null) return;

  const reduxState = JSON.parse(reduxStateString);
  const watchlistString = reduxState.watchlist;

  window.localStorage.setItem('watchlist', watchlistString);
};

const resetInvestmentsCache = () => {
  const investmentsCacheString = window.localStorage.getItem('investmentsCache') || 'null';
  if (Array.isArray(JSON.parse(investmentsCacheString))) {
    window.localStorage.removeItem('investmentsCache');
  }
};

export const runAllMigrations = async () => {
  moveIsAppVisited();
  moveHasFeedbackBeenClosed();
  moveWatchlist();
  resetInvestmentsCache();
  // Watchlist migration in stores
  await Preferences.migrate();
};
