import { css } from '@emotion/react';
import PriceRange from '../PriceRange';

const styles = {
  price: css`
    width: 114px;
  `,
};
const PlaygroundPage = () => {
  return (
    <div>
      <div css={styles.price}>
        <PriceRange highPrice={95000} lowPrice={847.2} markedPrice={50000} />
      </div>
      <div css={styles.price}>
        <PriceRange highPrice={959999} lowPrice={959000} markedPrice={959600} />
      </div>
      <div css={styles.price}>
        <PriceRange highPrice={100} lowPrice={0} markedPrice={80} />
      </div>
      <div css={styles.price}>
        <PriceRange highPrice={0.4} lowPrice={0.05} markedPrice={0.2} />
      </div>
    </div>
  );
};

export default PlaygroundPage;
