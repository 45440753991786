import { isNil } from 'lodash';
import { Preferences } from '@capacitor/preferences';

export const set = async (key: string, value: unknown): Promise<void> => {
  await Preferences.set({
    key,
    value: JSON.stringify(value),
  });
};

export const get = async <Type>(key: string): Promise<Type | undefined> => {
  const ret = await Preferences.get({ key });
  if (isNil(ret.value)) return undefined;
  return JSON.parse(ret.value);
};
