import { remove } from 'lodash';
import { App, BackButtonListener } from '@capacitor/app';

const callbacks: (BackButtonListener | undefined)[] = [];
export const init = () => {
  App.addListener('backButton', (options) => {
    const callback = callbacks[callbacks.length - 1];
    if (callback) {
      callback(options);
    }
  });
};

export const addBackListener = (callback: BackButtonListener) => {
  callbacks.push(callback);
};

export const removeBackListener = (callback: BackButtonListener) => {
  remove(callbacks, (n) => n === callback);
};
