import { css } from '@emotion/react';
import { posthog } from 'posthog-js';
import { sub, format, getDay } from 'date-fns';
import { uniq, isNil, maxBy, max, min, flatten, minBy } from 'lodash';
import { useCallback, useState, HTMLProps, FC } from 'react';
import { Spinner } from '@chakra-ui/react';
import {
  VictoryArea,
  VictoryPortal,
  VictoryCursorContainer,
  VictoryLine,
  VictoryScatter,
  LineSegment,
  VictoryGroup,
} from 'victory';
import { VictoryCommonPrimitiveProps } from 'victory-core';
import Button from '../../Button';
import * as stores from '../../../stores';

import { TimeRange } from '../../../types';
import ErrorSection from '../../ErrorSection';

import { colors, fontStyles, spacing } from '../../../styles';
import Wiim from './Wiim';

export interface ChartNewsData extends ChartElement {
  x: number;
  y: number;
}

interface Props extends HTMLProps<HTMLDivElement> {
  strokeColor: string;
  charts?: Record<TimeRange, ChartElement[] | undefined>;
  isLoading: boolean;
  isError: boolean;
  onRetry: () => void;
  timeRange?: Exclude<TimeRange, TimeRange.ytd>;
  selectedNewsElement?: ChartNewsData;
  onSelectNews: (chartElement?: ChartNewsData) => void;
  scannedPrice?: ChartNewsData;
  onScanPrice: (chartElement?: ChartNewsData | undefined) => void;
  ticker?: string;
}

const Chart: FC<Props> = ({
  charts = {},
  isLoading,
  isError,
  onRetry,
  timeRange,
  strokeColor,
  selectedNewsElement,
  onSelectNews,
  scannedPrice,
  onScanPrice,
  ticker,
}) => {
  const [isTouchMoving, setIsTouchMoving] = useState<boolean>(false);
  const [noDateXPosition, setNoDateXPosition] = useState<number>(0);
  const selectedChart = (!isLoading && !isError && timeRange && charts?.[timeRange]) || [];
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);

  const fractionOfDomainWithData = Math.min(
    1,
    (() => {
      if (!timeRange || selectedChart.length === 0 || timeRange === '1d') return 1;
      if (timeRange === '1w') {
        const numberOfDays = uniq(selectedChart.map((element) => getDay(element.date))).length;
        return numberOfDays / 5;
      }

      const expectedFirstDates = {
        '1m': sub(new Date(), { days: 28 }),
        '3m': sub(new Date(), { months: 3 }),
        '1y': sub(new Date(), { years: 1 }),
        '5y': sub(new Date(), { years: 5 }),
      };

      const fraction =
        (new Date().getTime() - selectedChart[0].date.getTime()) /
        (new Date().getTime() - expectedFirstDates[timeRange].getTime());

      // Allow for some slack if the ratio is close to 1.
      return fraction > 0.95 ? 1 : fraction;
    })(),
  );

  const shiftXValuesAmount = Math.floor(selectedChart.length / fractionOfDomainWithData - selectedChart.length);

  const chartData = selectedChart.map((element, index) => ({
    x: index + shiftXValuesAmount,
    y: element.price,
    articleHeadline: element.articleHeadline,
    price: element.price,
    changeRatio: element.changeRatio,
    date: element.date,
    minute: element.minute,
  }));

  const scatterData = chartData.filter((element) => element.articleHeadline);

  const formatTickLabel = (price?: number) => {
    if (price === undefined) return undefined;

    if (price > 10) {
      return `$${price.toFixed(0)}`;
    }

    return `$${price.toFixed(2)}`;
  };
  const maxPrice = max(chartData.map(({ y }) => y));
  const minPrice = min(chartData.map(({ y }) => y));
  const maxLabelValue = formatTickLabel(maxPrice);
  const minLabelValue = formatTickLabel(minPrice);

  const scanPrice = (xValue?: number) => {
    if (isNil(xValue)) {
      onSelectNews();
      onScanPrice();
      return;
    }

    const adjustedX = Math.round(xValue) - shiftXValuesAmount;
    const point = chartData[adjustedX];

    const maxDistance = chartData.length * 0.02;

    const closestPointWithArticleWithinDistance = minBy(
      scatterData.filter((element) => Math.abs(element.x - xValue) < maxDistance),
      (element) => Math.abs(element.x - xValue),
    );

    if (!isTouchMoving && !closestPointWithArticleWithinDistance) {
      onSelectNews();
      onScanPrice();
      return;
    }

    if (!isTouchMoving) {
      onScanPrice(closestPointWithArticleWithinDistance);
      onSelectNews(closestPointWithArticleWithinDistance);
    }

    onScanPrice(point);
    onSelectNews(closestPointWithArticleWithinDistance);
  };

  const minimumNewsText =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.';
  const longestNewsElementText = maxBy(
    [minimumNewsText, ...flatten(Object.values(charts)).map((chartElement) => chartElement?.articleHeadline)],
    (text) => {
      if (!text) return Number.NEGATIVE_INFINITY;
      return text.length;
    },
  ) as string;

  const subscriptionElement = (
    <>
      Historical insights are reserved for subscribers. Start your free trial to unlock.{' '}
      <Button
        onClick={() =>
          stores.General.update((s) => {
            s.isSubscriptionTakeoverShown = true;
          })
        }
        variant="secondary"
        block
        css={css`
          margin-top: ${spacing.deci};
          padding: ${spacing.milli};
        `}
      >
        Learn more
      </Button>
    </>
  );

  const latestNewsElement = maxBy(flatten(Object.values(charts)), (chartElement) => {
    if (!chartElement?.articleHeadline || !chartElement?.date) return Number.NEGATIVE_INFINITY;
    return chartElement.date.valueOf();
  });

  const displayedNewsElement: ChartElement | undefined = selectedNewsElement || latestNewsElement;

  const determineDotHighlighted = (data: VictoryCommonPrimitiveProps & { datum?: ChartNewsData }): boolean =>
    !!(selectedNewsElement?.articleHeadline && selectedNewsElement?.x === data.datum?.x);

  const noDateText = (() => {
    const date = chartData[0]?.date;
    if (!timeRange || shiftXValuesAmount === 0 || !date || timeRange === '1d') return '';
    const mapping = {
      '1w': format(date, 'EEEE'),
      '1m': format(date, 'MMMM d'),
      '3m': format(date, 'MMMM d'),
      '1y': format(date, 'MMMM y'),
      '5y': format(date, 'MMMM y'),
    };

    return `No data before ${mapping[timeRange]}`;
  })();

  const noDateRef = useCallback((node) => {
    if (node !== null) {
      setNoDateXPosition(node.getBoundingClientRect().x);
    }
  }, []);

  const isFreeTicker = (ticker?: string): boolean => {
    if (!ticker) return false;
    return ['AAPL', 'TSLA'].includes(ticker.toUpperCase());
  };

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      {(isError || isLoading) && (
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
          `}
        >
          {isError ? (
            <ErrorSection text="Problem fetching chart data." onRetry={onRetry} />
          ) : (
            <Spinner color={colors.cloud} size="lg" />
          )}
        </div>
      )}
      <div
        css={css`
          position: relative;
        `}
      >
        {displayedNewsElement?.articleHeadline && (
          <div
            css={css`
              position: absolute;
            `}
          >
            <Wiim
              text={
                purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive || // is subscriber
                isFreeTicker(ticker) || // is AAPL or TSLA
                !selectedNewsElement // is latest WIIM (not hovering over a specific date)
                  ? displayedNewsElement.articleHeadline
                  : subscriptionElement
              }
              isLatest={!selectedNewsElement}
            />
          </div>
        )}
        <div
          css={css`
            visibility: hidden;
          `}
        >
          <Wiim text={longestNewsElementText} />
        </div>
      </div>
      <div
        css={css`
          margin-top: ${spacing.deci};
          margin-bottom: ${spacing.milli};
          color: ${colors.silver};
          visibility: ${maxLabelValue ? 'visible' : 'hidden'};
          ${fontStyles.flea};
        `}
      >
        {maxLabelValue || '123 skeleton'}
      </div>
      <div
        css={css`
          position: relative;
          left: -${spacing.deci};
          width: calc(100% + ${spacing.deci} + ${spacing.deci});
          max-width: 600px;
          -webkit-tap-highlight-color: transparent;
          -webkit-touch-callout: none; /* iOS Safari */
          -webkit-user-select: none; /* Safari */
        `}
        onTouchStart={() => {
          setIsTouchMoving(false);
          posthog.capture('Touched Chart');
        }}
        onTouchMove={() => {
          setIsTouchMoving(true);
        }}
        onTouchEnd={() => {
          setIsTouchMoving(false);
          if (isTouchMoving) scanPrice();
        }}
      >
        {shiftXValuesAmount > 0 && (
          <div
            css={css`
              z-index: 1;
              position: absolute;
              top: 50%;
              right: ${fractionOfDomainWithData * 100}%;
              padding: 0 ${spacing.deci};
              transform: translateY(-50%);
              color: ${colors.silver};
              visibility: ${noDateXPosition < 0 ? 'hidden' : 'visible'};
              ${fontStyles.flea}
            `}
            ref={noDateRef}
          >
            {noDateText}
          </div>
        )}
        <VictoryGroup
          padding={0}
          height={200}
          domainPadding={{ y: 5 }}
          domain={
            chartData.length > 1
              ? {
                  x: [0, chartData[chartData.length - 1].x],
                  y:
                    minPrice === undefined || maxPrice === undefined || minPrice === maxPrice
                      ? undefined
                      : [minPrice, maxPrice],
                }
              : undefined
          }
          singleQuadrantDomainPadding={false}
          containerComponent={
            <VictoryCursorContainer
              cursorDimension="x"
              onCursorChange={(value: any) => scanPrice(value)}
              cursorComponent={<LineSegment style={{ stroke: scannedPrice ? colors.cloud : 'transparent' }} />}
              style={{ touchAction: 'auto' }}
            />
          }
        >
          <VictoryLine
            name="line"
            data={chartData}
            interpolation="monotoneX"
            style={{
              data: {
                stroke: strokeColor,
                strokeWidth: '3px',
                strokeLinejoin: 'round',
                strokeLinecap: 'round',
              },
            }}
          />
          {shiftXValuesAmount > 0 && (
            <VictoryArea
              data={[
                { x: 0, y: maxPrice },
                { x: chartData[0].x, y: maxPrice },
              ]}
              style={{
                data: {
                  fill: colors.fog,
                },
              }}
            />
          )}
          {shiftXValuesAmount > 0 && (
            <VictoryLine
              data={[
                { x: chartData[0].x, y: 0 },
                { x: chartData[0].x, y: maxPrice },
              ]}
              style={{
                data: { stroke: colors.cloud, strokeDasharray: '7' },
              }}
            />
          )}
          <VictoryPortal>
            <VictoryScatter
              data={scatterData}
              size={(data) => (determineDotHighlighted(data) ? 8 : 6)}
              style={{
                data: {
                  fill: (data) => (determineDotHighlighted(data) ? colors.petrolBlue : colors.sugar),
                  stroke: colors.petrolBlue,
                  strokeWidth: '3px',
                },
              }}
            />
          </VictoryPortal>
        </VictoryGroup>
      </div>
      <div
        css={css`
          margin-top: ${spacing.milli};
          margin-bottom: ${spacing.regular};
          color: ${colors.silver};
          visibility: ${minLabelValue ? 'visible' : 'hidden'};
          ${fontStyles.flea};
        `}
      >
        {minLabelValue || '123 skeleton'}
      </div>
    </div>
  );
};

export default Chart;
