import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { groupBy, zip } from 'lodash';
import { FC, useState, useEffect } from 'react';
import { colors, fontStyles, spacing } from '../../../styles';
import ConditionalLink from '../../ConditionalLink';
import Button from '../../Button';
import marketFinancials from '../../../utils/marketFinancials';
import { getStockStats } from '../../../apis/backend';
import ErrorSection from '../../ErrorSection';
import buildStats from './buildStats';
import CompareSearchTakeover from './CompareSearchTakeover';

interface Props {
  baseSymbol: string;
}

const KeyStatsPage: FC<Props> = ({ baseSymbol }) => {
  const [isCompareSearchShown, setIsCompareSearchShown] = useState<boolean>();
  const [comparisonSymbol, setComparisonSymbol] = useState<string>();
  const baseQuery = useQuery(['investments', baseSymbol, 'stats'], async () => getStockStats(baseSymbol));
  const errorToastId = 'getComparisonStatsError';
  const comparisonQuery = useQuery(
    ['investments', comparisonSymbol, 'stats'],
    async () => (comparisonSymbol ? getStockStats(comparisonSymbol) : undefined),
    {
      enabled: !!comparisonSymbol,
      onError: () => {
        toast(
          () => (
            <Button
              css={css`
                width: 100%;
                display: flex;
                justify-content: space-between;
                outline: none;
                color: ${colors.liquorice};
              `}
              onClick={() => {
                toast.dismiss(errorToastId);
                comparisonQuery.refetch();
              }}
            >
              <span>Problem loading data for {comparisonSymbol}.</span>
              <span
                css={css`
                  color: ${colors.petrolBlue};
                  margin-left: 4px;
                `}
              >
                Retry
              </span>
            </Button>
          ),
          { toastId: errorToastId, autoClose: false },
        );
      },
    },
  );

  useEffect(() => {
    return () => {
      toast.dismiss(errorToastId);
    };
  }, []);

  if (baseQuery.isLoading) return <div />;
  if (baseQuery.isError || !baseQuery.data) {
    return (
      <div
        css={css`
          margin-top: 40vh;
        `}
      >
        <ErrorSection text="Problem loading stats." onRetry={() => baseQuery.refetch()} />
      </div>
    );
  }

  const styles = {
    keyStats: css`
      position: relative;
    `,
    headingWrapper: css`
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: ${spacing.regular};
    `,
    heading: css`
      margin: 0;
      ${fontStyles.shark};
    `,
    compareButton: css`
      padding-bottom: 2px;
      ${fontStyles.mouse};
    `,
    table: css`
      text-align: right;
      width: 100%;
    `,
    sectionHeader: css`
      ${fontStyles.lion};
    `,
    notFirstSectionHeader: css`
      padding-top: ${spacing.deci};
    `,
    leftColumn: css`
      text-align: left;
    `,
    statHeader: css`
      ${fontStyles.cat};
    `,
    value: css`
      ${fontStyles.cat};
    `,
  };

  const items = buildStats(baseQuery.data, marketFinancials);
  const comparisonItems = comparisonQuery.data ? buildStats(comparisonQuery.data, marketFinancials) : null;

  const itemsBySection = groupBy(zip(items, comparisonItems), ([item]: any) => item.section);

  return (
    <div css={styles.keyStats}>
      <CompareSearchTakeover
        baseSymbol={baseSymbol}
        isShown={!!isCompareSearchShown}
        onClose={() => setIsCompareSearchShown(false)}
        onSelectComparison={(symbol) => {
          setIsCompareSearchShown(false);
          setComparisonSymbol(symbol);
        }}
      />
      <div css={styles.headingWrapper}>
        <h2 css={styles.heading}>
          Key Stats <span css={{ ...fontStyles.cat }}>past 12m</span>
        </h2>
        <Button onClick={() => setIsCompareSearchShown(true)} css={styles.compareButton}>
          Compare
        </Button>
      </div>
      <table css={styles.table}>
        {Object.keys(itemsBySection).map((section, index) => (
          <tbody key={section}>
            <tr>
              <th css={[styles.sectionHeader, index > 0 && styles.notFirstSectionHeader, styles.leftColumn]}>
                {section}
              </th>
              <th css={[styles.sectionHeader, index > 0 && styles.notFirstSectionHeader]}>{baseSymbol}</th>
              {comparisonSymbol && (
                <th css={[styles.sectionHeader, index > 0 && styles.notFirstSectionHeader]}>{comparisonSymbol}</th>
              )}
            </tr>
            {itemsBySection[section].map(([baseItem, comparisonItem]: any) => (
              <tr key={baseItem.id}>
                <th css={[styles.statHeader, styles.leftColumn]}>
                  <ConditionalLink to={baseItem.url}>{baseItem.name}</ConditionalLink>
                </th>
                <td css={styles.value}>{baseItem.displayValue}</td>
                {comparisonItem && <td css={styles.value}>{comparisonItem.displayValue}</td>}
              </tr>
            ))}
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default KeyStatsPage;
