import { Share } from '@capacitor/share';
import { appStoreUrl, playStoreUrl } from '../constants';

export default async () => {
  try {
    await Share.share({
      // Email subject line
      title: 'Check out Bloom — the stock research app',

      // Cannot be edited in Android before sending
      text: `Check out Bloom, stock research for investors, not traders.

On iOS: ${appStoreUrl}

On Android: ${playStoreUrl}`,
    });
  } catch (e) {
    // most likely a user cancel, which also throws
  }
};
