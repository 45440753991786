import Search from '../../Search';
import Takeover from '../../Takeover';

interface Props {
  baseSymbol: string;
  isShown: boolean;
  onClose: () => void;
  onSelectComparison: (symbol: string) => void;
}

const CompareSearchTakeover = ({ isShown, baseSymbol, onClose, onSelectComparison }: Props) => {
  return (
    <Takeover isShown={isShown} title={`Compare ${baseSymbol} with ... `} onClose={onClose} name="CompareSearch">
      <Search onResultClick={onSelectComparison} autoFocus excludedSymbols={[baseSymbol]} />
    </Takeover>
  );
};

export default CompareSearchTakeover;
