import { css } from '@emotion/react';
import { FC, ComponentPropsWithoutRef } from 'react';
import { colors, fontStyles, spacing } from '../styles';

export type Props = ComponentPropsWithoutRef<'label'> & ComponentPropsWithoutRef<'input'>;

const RadioButton: FC<Props> = ({ children, value, checked, onChange, ...props }: Props) => {
  return (
    <label
      css={[
        css`
          position: relative;
          border: ${colors.cloud} 2px solid;
          color: ${colors.tungsten};
          background-color: transparent;
          padding: ${spacing.milli} ${spacing.centi};
          min-width: 42px;
          text-align: center;
          border-radius: 24px;
          cursor: pointer;
          -webkit-tap-highlight-color: transparent;
          ${fontStyles.mouse}
          html[data-theme='dark'] & {
            color: ${colors.sugar};
          }
        `,
        checked &&
          css`
            color: ${colors.petrolBlue};
            border-color: ${colors.petrolBlue};
          `,
      ]}
      {...props}
    >
      {children}
      <input
        type="radio"
        value={value}
        checked={checked}
        onChange={onChange}
        css={css`
          position: absolute;
          visibility: hidden;
        `}
      />
    </label>
  );
};

export default RadioButton;
