import { FC } from 'react';
import { css } from '@emotion/react';
import { Rating } from '../../apis/backend';

// Default images
import missing from './defaultImages/missing.svg';
import dangerous from './defaultImages/dangerous.svg';
import exceptional from './defaultImages/exceptional.svg';
import favorable from './defaultImages/favorable.svg';
import average from './defaultImages/average.svg';
import unfavorable from './defaultImages/unfavorable.svg';

// Growth images
import growthAverage from './growthImages/average.svg';
import growthDangerous from './growthImages/dangerous.svg';
import growthExceptional from './growthImages/exceptional.svg';
import growthFavorable from './growthImages/favorable.svg';
import growthUnfavorable from './growthImages/unfavorable.svg';

// Insider images
import insiderAverage from './insiderImages/average.svg';
import insiderDangerous from './insiderImages/dangerous.svg';
import insiderExceptional from './insiderImages/exceptional.svg';
import insiderFavorable from './insiderImages/favorable.svg';
import insiderUnfavorable from './insiderImages/unfavorable.svg';

// Price images
import priceAverage from './priceImages/average.svg';
import priceDangerous from './priceImages/dangerous.svg';
import priceExceptional from './priceImages/exceptional.svg';
import priceFavorable from './priceImages/favorable.svg';
import priceUnfavorable from './priceImages/unfavorable.svg';

// Value images
import valueAverage from './valueImages/average.svg';
import valueDangerous from './valueImages/dangerous.svg';
import valueExceptional from './valueImages/exceptional.svg';
import valueFavorable from './valueImages/favorable.svg';
import valueUnfavorable from './valueImages/unfavorable.svg';

export enum Size {
  small,
  medium,
  large,
}

interface Props {
  heading: string;
  rating: Rating;
  size?: Size;
}

export { Rating };

const profitabilityImages = {
  [Rating.Missing]: missing,
  [Rating.Exceptional]: priceExceptional,
  [Rating.Favorable]: priceFavorable,
  [Rating.Average]: priceAverage,
  [Rating.Unfavorable]: priceUnfavorable,
  [Rating.Dangerous]: priceDangerous,
};

const imageMappings: { [heading: string]: { [key: string]: string } } = {
  profitability: profitabilityImages,
  profits: profitabilityImages,
  price: {
    [Rating.Missing]: missing,
    [Rating.Exceptional]: valueExceptional,
    [Rating.Favorable]: valueFavorable,
    [Rating.Average]: valueAverage,
    [Rating.Unfavorable]: valueUnfavorable,
    [Rating.Dangerous]: valueDangerous,
  },
  growth: {
    [Rating.Missing]: missing,
    [Rating.Exceptional]: growthExceptional,
    [Rating.Favorable]: growthFavorable,
    [Rating.Average]: growthAverage,
    [Rating.Unfavorable]: growthUnfavorable,
    [Rating.Dangerous]: growthDangerous,
  },
  insider: {
    [Rating.Missing]: missing,
    [Rating.Exceptional]: insiderExceptional,
    [Rating.Favorable]: insiderFavorable,
    [Rating.Average]: insiderAverage,
    [Rating.Unfavorable]: insiderUnfavorable,
    [Rating.Dangerous]: insiderDangerous,
  },
  '': {
    [Rating.Missing]: missing,
    [Rating.Exceptional]: exceptional,
    [Rating.Favorable]: favorable,
    [Rating.Average]: average,
    [Rating.Unfavorable]: unfavorable,
    [Rating.Dangerous]: dangerous,
  },
  return: {
    [Rating.Missing]: missing,
    [Rating.Exceptional]: exceptional,
    [Rating.Favorable]: favorable,
    [Rating.Average]: average,
    [Rating.Unfavorable]: unfavorable,
    [Rating.Dangerous]: dangerous,
  },
  risk: {
    [Rating.Missing]: missing,
    [Rating.Exceptional]: exceptional,
    [Rating.Favorable]: favorable,
    [Rating.Average]: average,
    [Rating.Unfavorable]: unfavorable,
    [Rating.Dangerous]: dangerous,
  },
  size: {
    [Rating.Missing]: missing,
    [Rating.Exceptional]: exceptional,
    [Rating.Favorable]: favorable,
    [Rating.Average]: average,
    [Rating.Unfavorable]: unfavorable,
    [Rating.Dangerous]: dangerous,
  },
  fees: {
    [Rating.Missing]: missing,
    [Rating.Exceptional]: exceptional,
    [Rating.Favorable]: favorable,
    [Rating.Average]: average,
    [Rating.Unfavorable]: unfavorable,
    [Rating.Dangerous]: dangerous,
  },
};

const baseStyles = css`
  vertical-align: middle;
`;

const styles = {
  [Size.small]: css`
    height: 16px;
    width: 16px;
  `,
  [Size.medium]: css`
    height: 24px;
    width: 24px;
  `,
  [Size.large]: css`
    height: 32px;
    width: 32px;
  `,
};

const Indicator: FC<Props> = ({ heading, rating, size = Size.medium }) => {
  const finalHeading = heading?.split(' ')[0].toLowerCase();
  const image = imageMappings[finalHeading]?.[rating] ?? missing;
  return <img src={image} css={[baseStyles, styles[size]]} />;
};

export default Indicator;
