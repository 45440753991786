import { FC, useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { Spinner } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { colors, spacing, fontStyles, buildPageSpacing } from '../../styles';
import Page from '../Page';
import Button from '../Button';
import { sendChatMessage, getSuggestedQuestions } from '../../apis/backend';
import * as stores from '../../stores';
import SendIcon from './Send.svg';

interface ChatMessage {
  role: 'user' | 'assistant';
  content: string;
  timestamp?: string;
}

// Add new interface for daily message tracking
interface DailyMessageCount {
  count: number;
  date: string;
}

const MAX_FREE_MESSAGES = 10;

// Helper function to get today's date in user's timezone
const getTodayInUserTimezone = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), now.getDate()).toISOString();
};

const ChatPage: FC = () => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [suggestedQuestions, setSuggestedQuestions] = useState<string[]>([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const isKeyboardVisible = stores.General.useState((s) => s.isKeyboardVisible);
  const [messageCount, setMessageCount] = useState<number>(0);
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const savedMessages = localStorage.getItem('chatHistory');
    const savedSuggestions = localStorage.getItem('chatSuggestions');
    const savedDailyCount = localStorage.getItem('dailyMessageCount');

    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
    if (savedSuggestions) {
      setSuggestedQuestions(JSON.parse(savedSuggestions));
    }
    if (savedDailyCount) {
      const dailyCount: DailyMessageCount = JSON.parse(savedDailyCount);
      const today = getTodayInUserTimezone();

      if (dailyCount.date === today) {
        setMessageCount(dailyCount.count);
      } else {
        // Reset count for new day
        setMessageCount(0);
        localStorage.setItem('dailyMessageCount', JSON.stringify({ count: 0, date: today }));
      }
    }
  }, []);

  useEffect(() => {
    const serializableMessages = messages.map(({ role, content, timestamp }) => ({
      role,
      content,
      timestamp,
    }));
    localStorage.setItem('chatHistory', JSON.stringify(serializableMessages));
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleKeyboardShow = () => {
      window.visualViewport?.addEventListener('resize', handleViewportResize);
    };

    const handleKeyboardHide = () => {
      window.visualViewport?.removeEventListener('resize', handleViewportResize);
      setKeyboardHeight(0);
    };

    const handleViewportResize = () => {
      if (window.visualViewport) {
        const keyboardHeight = window.innerHeight - window.visualViewport.height;
        setKeyboardHeight(keyboardHeight);
      }
    };

    window.addEventListener('focus', handleKeyboardShow, true);
    window.addEventListener('blur', handleKeyboardHide, true);

    return () => {
      window.removeEventListener('focus', handleKeyboardShow, true);
      window.removeEventListener('blur', handleKeyboardHide, true);
      window.visualViewport?.removeEventListener('resize', handleViewportResize);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('chatSuggestions', JSON.stringify(suggestedQuestions));
  }, [suggestedQuestions]);

  const fetchSuggestedQuestions = async (apiMessages: ChatMessage[]) => {
    try {
      setIsSuggestionsLoading(true);
      const suggestionsResponse = await getSuggestedQuestions(apiMessages);
      setSuggestedQuestions(suggestionsResponse.questions);
    } catch (error) {
      console.error('Suggestions API error:', error);
    } finally {
      setIsSuggestionsLoading(false);
    }
  };

  const handleSendMessage = async (messageText: string = inputValue.trim()) => {
    if (!messageText) return;

    setInputValue('');
    const newUserMessage: ChatMessage = {
      role: 'user',
      content: messageText,
      timestamp: new Date().toISOString(),
    };

    if (!isSubscribed && messageCount >= MAX_FREE_MESSAGES) {
      const subscriptionMessage: ChatMessage = {
        role: 'assistant',
        content: 'You have no more free messages left today. [Subscribe now](#subscribe) to continue chatting.',
        timestamp: new Date().toISOString(),
      };
      setMessages([...messages, newUserMessage, subscriptionMessage]);
      return;
    }

    const updatedMessages = [...messages, newUserMessage];
    setMessages(updatedMessages);
    setIsLoading(true);
    setSuggestedQuestions([]);

    try {
      const apiMessages = updatedMessages.map(({ role, content }) => ({
        role,
        content,
      }));

      const response = await sendChatMessage(apiMessages);

      let content = response.text;
      if (response.citations) {
        response.citations.forEach((citation, index) => {
          const refNumber = index + 1;
          content = content.replaceAll(`[${refNumber}]`, `\[[${refNumber}](${citation})\]`);
        });
      }

      content = content.replace(/(?<!\[)\$([A-Z]+)/g, '[$$$1](/symbol/$1)');

      const assistantMessage: ChatMessage = {
        role: 'assistant',
        content: content,
        timestamp: new Date().toISOString(),
      };

      setMessages([...updatedMessages, assistantMessage]);

      const suggestionsApiMessages = [
        ...apiMessages,
        {
          role: 'assistant' as ChatMessage['role'],
          content: response.text,
        },
      ];

      fetchSuggestedQuestions(suggestionsApiMessages);
    } catch (error) {
      console.error('Chat API error:', error);
    } finally {
      setIsLoading(false);
    }

    const newCount = messageCount + 1;
    setMessageCount(newCount);
    const today = getTodayInUserTimezone();
    localStorage.setItem('dailyMessageCount', JSON.stringify({ count: newCount, date: today }));
  };

  const handleClearChat = () => {
    setMessages([]);
    setSuggestedQuestions([]);
    localStorage.removeItem('chatHistory');
    localStorage.removeItem('chatSuggestions');
  };

  // Add these default questions
  const defaultQuestions = [
    'Summarize the latest news for Netflix',
    'What are some common investing strategies?',
    'Is Apple a good buy?',
    'What can I do with Bloom?',
  ];

  return (
    <Page showNavigationBar>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 100%;
          padding-bottom: calc(${spacing.giga} + ${buildPageSpacing(spacing.regular, 'BOTTOM')});
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 ${spacing.deci} 0;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: ${spacing.milli};
            `}
          >
            <h1
              css={css`
                color: ${colors.liquorice};
                margin: 0;
                ${fontStyles.shark}
                html[data-theme='dark'] & {
                  color: ${colors.sugar};
                }
              `}
            >
              Chat
            </h1>
            {isSubscribed && (
              <div
                css={css`
                  background: ${colors.petrolBlue};
                  color: ${colors.sugar};
                  padding: 2px ${spacing.milli};
                  border-radius: 4px;
                  ${fontStyles.flea}
                  text-transform: uppercase;
                  letter-spacing: 0.5px;
                  html[data-theme='dark'] & {
                    background: ${colors.skyBlue};
                  }
                `}
              >
                Pro
              </div>
            )}
          </div>
          <Button
            onClick={handleClearChat}
            css={css`
              ${fontStyles.dog}
              padding: ${spacing.milli} ${spacing.deci};
              color: ${colors.silver};

              &:hover {
                color: ${colors.petrolBlue};
              }

              html[data-theme='dark'] & {
                color: ${colors.cloud};
                &:hover {
                  color: ${colors.skyBlue};
                }
              }
            `}
          >
            Clear
          </Button>
        </div>

        <div
          css={css`
            flex: 1;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            min-height: 0;
          `}
        >
          {messages.length === 0 ? (
            <div
              css={css`
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: ${spacing.regular};
                padding: ${spacing.regular} 0;
              `}
            >
              <div
                css={css`
                  width: 48px;
                  height: 48px;
                  background: ${colors.petrolBlue};
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: ${colors.sugar};
                  ${fontStyles.shark}
                `}
              >
                B
              </div>
              <h2
                css={css`
                  ${fontStyles.shark}
                  color: ${colors.liquorice};
                  margin: 0;
                  html[data-theme='dark'] & {
                    color: ${colors.sugar};
                  }
                `}
              >
                How can I help you today?
              </h2>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  gap: ${spacing.regular};
                  width: 100%;
                  max-width: 768px;
                  padding: 0 ${spacing.regular};
                `}
              >
                {defaultQuestions.map((question) => (
                  <button
                    key={question}
                    onClick={() => handleSendMessage(question)}
                    css={css`
                      padding: ${spacing.deci} ${spacing.regular};
                      border: 1px solid var(--border-color);
                      border-radius: 8px;
                      background: transparent;
                      color: var(--text-color);
                      text-align: left;
                      cursor: pointer;
                      ${fontStyles.cat}
                      &:hover {
                        background: rgba(0, 0, 0, 0.05);
                      }
                      html[data-theme='dark'] & {
                        &:hover {
                          background: rgba(255, 255, 255, 0.05);
                        }
                      }
                    `}
                  >
                    {question}
                  </button>
                ))}
              </div>
            </div>
          ) : (
            <>
              {messages.map((message, index) => (
                <div
                  key={index}
                  css={css`
                    margin-bottom: ${spacing.regular};
                    display: flex;
                    flex-direction: column;
                    align-items: ${message.role === 'user' ? 'flex-end' : 'flex-start'};
                  `}
                >
                  <div
                    css={css`
                      background-color: ${message.role === 'user' ? colors.petrolBlue : colors.fog};
                      color: ${message.role === 'user' ? colors.sugar : colors.liquorice};
                      padding: ${spacing.deci};
                      border-radius: 12px;
                      max-width: 80%;
                      ${fontStyles.mouse}
                      html[data-theme='dark'] & {
                        background-color: ${message.role === 'user' ? colors.petrolBlue : colors.tungsten};
                        color: ${colors.sugar};
                      }

                      /* Markdown styles */
                      & p {
                        margin: 0 0 ${spacing.milli} 0;
                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                      & h1 {
                        ${fontStyles.lion}
                        margin: 0 0 ${spacing.centi} 0;
                      }
                      & h2 {
                        ${fontStyles.dog}
                        margin: 0 0 ${spacing.milli} 0;
                      }
                      & ul,
                      & ol {
                        margin: ${spacing.milli} 0;
                        padding-left: ${spacing.regular};
                      }
                      & li {
                        margin: ${spacing.milli} 0;
                      }
                      & code {
                        background-color: rgba(0, 0, 0, 0.1);
                        padding: 2px 4px;
                        border-radius: 4px;
                      }
                      & pre {
                        background-color: rgba(0, 0, 0, 0.1);
                        padding: ${spacing.milli};
                        border-radius: 4px;
                        overflow-x: auto;
                        margin: ${spacing.milli} 0;
                      }
                      & strong {
                        font-weight: 600;
                      }

                      & a {
                        color: ${colors.petrolBlue};
                        html[data-theme='dark'] & {
                          color: ${colors.skyBlue};
                        }
                      }

                      /* Table styles */
                      & table {
                        border-collapse: collapse;
                        margin: ${spacing.deci} 0;
                        width: 100%;
                        display: block;
                        overflow-x: auto;
                      }

                      & th,
                      & td {
                        border: 1px solid ${colors.cloud};
                        padding: ${spacing.milli};
                        text-align: left;
                      }

                      & th {
                        background-color: rgba(0, 0, 0, 0.1);
                        font-weight: 600;
                      }

                      & tr:nth-of-type(even) {
                        background-color: rgba(0, 0, 0, 0.03);
                      }

                      html[data-theme='dark'] & {
                        & th,
                        & td {
                          border-color: ${colors.tungsten};
                        }
                        & th {
                          background-color: rgba(255, 255, 255, 0.1);
                        }
                        & tr:nth-of-type(even) {
                          background-color: rgba(255, 255, 255, 0.03);
                        }
                      }
                    `}
                  >
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      components={{
                        table: ({ ...props }) => <table {...props} />,
                        td: ({ ...props }) => <td {...props} />,
                        th: ({ ...props }) => <th {...props} />,
                        a: ({ href, children, ...props }) => {
                          const isInternal = href?.startsWith('/') || href === '#subscribe';
                          if (isInternal) {
                            if (href === '#subscribe') {
                              return (
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    stores.General.update((s) => {
                                      s.isSubscriptionTakeoverShown = true;
                                    });
                                  }}
                                  {...props}
                                >
                                  {children}
                                </Link>
                              );
                            }
                            return (
                              <Link to={href || ''} {...props}>
                                {children}
                              </Link>
                            );
                          }
                          return (
                            <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
                              {children}
                            </a>
                          );
                        },
                      }}
                    >
                      {String(message.content)}
                    </ReactMarkdown>
                  </div>
                </div>
              ))}
              {!isLoading && (isSuggestionsLoading || suggestedQuestions.length > 0) && (
                <div
                  css={css`
                    display: flex;
                    flex-wrap: wrap;
                    gap: ${spacing.milli};
                    min-height: 40px;
                  `}
                >
                  {isSuggestionsLoading ? (
                    <Spinner size="sm" color={colors.cloud} />
                  ) : (
                    suggestedQuestions.map((question, index) => (
                      <button
                        key={index}
                        onClick={() => handleSendMessage(question)}
                        css={css`
                          padding: 12px ${spacing.deci};
                          border: 1px solid var(--border-color);
                          border-radius: 16px;
                          background: transparent;
                          color: var(--text-color);
                          cursor: pointer;
                          ${fontStyles.flea}
                          &:hover {
                            background: rgba(0, 0, 0, 0.05);
                          }
                          html[data-theme='dark'] & {
                            &:hover {
                              background: rgba(255, 255, 255, 0.05);
                            }
                          }
                        `}
                      >
                        {question}
                      </button>
                    ))
                  )}
                </div>
              )}
            </>
          )}
          {isLoading && (
            <div
              css={css`
                display: flex;
                justify-content: center;
                margin: ${spacing.regular} 0;
              `}
            >
              <Spinner color={colors.cloud} size="lg" />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        <div
          css={css`
            position: fixed;
            bottom: ${isKeyboardVisible ? keyboardHeight : 0}px;
            left: 0;
            right: 0;
            padding: ${spacing.deci} ${buildPageSpacing(spacing.deci, 'LEFT')}
              calc(${!isKeyboardVisible ? spacing.giga : '0px'} + ${buildPageSpacing(spacing.regular, 'BOTTOM')})
              ${buildPageSpacing(spacing.deci, 'RIGHT')};
            background-color: var(--background-color);
            border-top: 1px solid var(--border-color);
            transform: translateZ(0);
            will-change: bottom;
          `}
        >
          <div
            css={css`
              position: relative;
              display: flex;
              max-width: 768px;
              margin: 0 auto;
            `}
          >
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSendMessage();
                }
              }}
              placeholder="Write a message..."
              css={css`
                width: 100%;
                padding: ${spacing.deci};
                padding-right: ${spacing.regular};
                border: 1px solid var(--border-color);
                border-radius: 8px;
                background-color: var(--background-color);
                color: var(--text-color);
                ${fontStyles.mouse}
                &:focus {
                  outline: none;
                  border-color: ${colors.petrolBlue};
                }
              `}
            />
            <Button
              onClick={() => handleSendMessage(inputValue.trim())}
              disabled={isLoading || !inputValue.trim()}
              css={css`
                position: absolute;
                right: ${spacing.milli};
                top: 50%;
                transform: translateY(-50%);
                padding: ${spacing.milli} ${spacing.milli};
                min-width: unset;
                background-color: ${colors.petrolBlue};
                color: ${colors.sugar};
                border-radius: 6px;

                &:disabled {
                  background-color: ${colors.silver};
                  color: ${colors.cloud};
                }

                html[data-theme='dark'] & {
                  background-color: ${colors.skyBlue};
                  &:disabled {
                    background-color: ${colors.tungsten};
                  }
                }

                &:hover:not(:disabled) {
                  background-color: ${colors.petrolBlue};
                }
              `}
            >
              <img src={SendIcon} />
            </Button>
          </div>
          {!isSubscribed && (
            <div
              css={css`
                text-align: center;
                margin-bottom: ${spacing.milli};
                ${fontStyles.mouse}
                color: ${messageCount >= MAX_FREE_MESSAGES ? colors.orange : colors.silver};
              `}
            >
              {MAX_FREE_MESSAGES - messageCount} / {MAX_FREE_MESSAGES} free messages left today
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default ChatPage;
