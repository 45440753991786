import { toast } from 'react-toastify';
import { css } from '@emotion/react';
import { Spinner } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { spacing, colors } from '../styles';

export default () => {
  const offlineId = 'offlineId';
  const onlineId = 'onlineId';

  const triggerOfflineToast = () => {
    toast(
      () => (
        <span
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <span>
            <Spinner color={colors.cloud} size="xs" />
          </span>
          <span
            css={css`
              flex: 1;
              margin-left: ${spacing.deci};
            `}
          >
            Trying to get back online. <br /> Please check your Internet connection.
          </span>
        </span>
      ),
      {
        toastId: offlineId,
        autoClose: false,
      },
    );
  };
  const triggerOnlineToast = () => {
    // Prevents race condition
    if (toast.isActive(offlineId)) {
      toast.dismiss(offlineId);
    }

    toast(
      () => (
        <span
          css={css`
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <span>
            <CheckCircleIcon color={colors.leaf} />
          </span>
          <span
            css={css`
              flex: 1;
              margin-left: ${spacing.deci};
            `}
          >
            You are back online.
          </span>
        </span>
      ),
      { toastId: onlineId },
    );
  };

  window.addEventListener('offline', triggerOfflineToast);
  window.addEventListener('online', triggerOnlineToast);

  // There appears to be a bug where this is always false
  // if (!navigator.onLine) triggerOfflineToast();
};
