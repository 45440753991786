import { FC, ComponentPropsWithoutRef } from 'react';
import { css } from '@emotion/react';
import { isNumber } from 'lodash';
import { transparentize } from 'polished';
import { calculateChangeColor } from '../../styles';
import formatDisplay, { FormatterStyle } from '../../utils/formatDisplay';
import { TimeRange } from '../../types';

interface Props extends ComponentPropsWithoutRef<'span'> {
  value?: number;
  prefix?: string;
  timeRange?: TimeRange | undefined;
}

const timeRangeDisplay: Record<TimeRange, string> = {
  [TimeRange.days1]: '',
  [TimeRange.weeks1]: 'last week',
  [TimeRange.months1]: 'last month',
  [TimeRange.months3]: 'last 3 mo',
  [TimeRange.ytd]: 'YTD',
  [TimeRange.years1]: 'last year',
  [TimeRange.years5]: 'last 5 yrs',
};

const ColoredPercentChange: FC<Props> = ({ value, prefix, timeRange, ...props }) => {
  return (
    <span
      css={css`
        color: ${isNumber(value) ? calculateChangeColor(value) : 'inherit'};
        background-color: ${isNumber(value) ? transparentize(0.9, calculateChangeColor(value)) : 'inherit'};
        padding: 6px 10px;
        border-radius: 4px;
      `}
      {...props}
    >
      {prefix && `${prefix} `}
      {formatDisplay(FormatterStyle.percentChange, value)}
      {timeRange && timeRangeDisplay[timeRange] && ` ${timeRangeDisplay[timeRange]}`}
    </span>
  );
};

export default ColoredPercentChange;
