import { FC } from 'react';
import { css } from '@emotion/react';
import { colors, spacing, fontStyles } from '../../styles';

type Theme = 'light' | 'dark' | 'auto';

interface Props {
  selectedTheme: Theme;
  onChange: (theme: Theme) => void;
}

const ThemeButton: FC<{
  isSelected: boolean;
  onClick: () => void;
  label: string;
}> = ({ isSelected, onClick, label }) => (
  <button
    onClick={onClick}
    css={css`
      padding: ${spacing.milli} ${spacing.centi};
      border-radius: 20px;
      border: 1px solid ${colors.petrolBlue};
      background-color: ${isSelected ? colors.petrolBlue : 'transparent'};
      color: ${isSelected ? colors.sugar : colors.petrolBlue};
      ${fontStyles.cat};
      transition: all 0.2s ease;

      &:hover {
        opacity: 0.8;
      }

      html[data-theme='dark'] & {
        border-color: ${colors.sugar};
        background-color: ${isSelected ? colors.sugar : 'transparent'};
        color: ${isSelected ? colors.liquorice : colors.sugar};
      }
    `}
  >
    {label}
  </button>
);

const ThemeSelector: FC<Props> = ({ selectedTheme, onChange }) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        background-color: var(--background-color);
      `}
    >
      <span
        css={css`
          ${fontStyles.cat};
          flex: 1;
          color: var(--text-color);
        `}
      >
        Enable dark mode
      </span>
      <div
        css={css`
          display: flex;
          gap: ${spacing.centi};
        `}
      >
        <ThemeButton isSelected={selectedTheme === 'light'} onClick={() => onChange('light')} label="Light" />
        <ThemeButton isSelected={selectedTheme === 'dark'} onClick={() => onChange('dark')} label="Dark" />
        <ThemeButton isSelected={selectedTheme === 'auto'} onClick={() => onChange('auto')} label="Auto" />
      </div>
    </div>
  );
};

export default ThemeSelector;
