import { FC } from 'react';
import { css } from '@emotion/react';
import Button from '../Button';
import { fontStyles, spacing } from '../../styles';

const ErrorSection: FC<{ text: string; onRetry: () => void }> = ({ text, onRetry }) => (
  <div
    css={css`
      text-align: center;
      ${fontStyles.cat}
    `}
  >
    <div>{text}</div>
    <div
      css={css`
        margin-top: ${spacing.centi};
      `}
    >
      <Button
        onClick={onRetry}
        css={css`
          ${fontStyles.mouse}
        `}
      >
        Try again.
      </Button>
    </div>
  </div>
);

export default ErrorSection;
