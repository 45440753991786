import { FC } from 'react';
import { css } from '@emotion/react';
import { fontStyles, spacing } from '../../../styles';
import Takeover, { Props as TakeoverProps } from '../../Takeover';

interface Props extends TakeoverProps {
  keyPoint?: string;
  details?: string;
}

const RiskDetailsTakeover: FC<Props> = ({ keyPoint, details, ...props }) => (
  <Takeover {...props} title="Risk Factor" name="RiskDetails">
    <h2
      css={css`
        margin: ${spacing.deka} 0 ${spacing.centi} 0;
        ${fontStyles.shark}
      `}
    >
      Key Point
    </h2>
    <p
      css={css`
        margin: 0;
        ${fontStyles.cat}
      `}
    >
      {keyPoint}
    </p>
    <h2
      css={css`
        margin: ${spacing.deka} 0 ${spacing.centi} 0;
        ${fontStyles.shark}
      `}
    >
      Details
    </h2>
    <p
      css={css`
        margin: 0;
        white-space: pre-line;
        ${fontStyles.cat}
      `}
    >
      {details}
    </p>
  </Takeover>
);

export default RiskDetailsTakeover;
