import { css } from '@emotion/react';
import { FC, useState, useEffect } from 'react';
import { getEtfBottomLine, GetEtfBottomLineResponse } from '../../../apis/backend';
import Hr from '../../Hr';
import { colors, fontStyles, spacing } from '../../../styles';
import SectionHeading from '../SectionHeading';
import Indicator, { Size } from '../../RatingIndicator';
import { reportError } from '../../../utils/reporting';

interface Props {
  symbol: string;
}

const Overview: FC<Props> = ({ symbol }) => {
  const [data, setData] = useState<GetEtfBottomLineResponse | undefined>();

  useEffect(() => {
    (async () => {
      try {
        setData(await getEtfBottomLine(symbol));
      } catch (e) {
        if (e instanceof Error) {
          reportError(e);
        } else {
          reportError(new Error(String(e)));
        }
      }
    })();
  }, [symbol]);

  if (!data || !data.details.some((detail) => detail.rating !== null && detail.description !== null)) {
    return null;
  }

  const headingMapping: { [index: number]: string } = {
    0: 'return',
    1: 'risk',
    2: 'size',
    3: 'fees',
  };

  return (
    <div>
      <Hr />
      <SectionHeading text="Bottom Line" />
      <ul
        css={css`
          margin: 0;
          list-style: none;
          position: relative;
          padding-left: ${spacing.hecto};
        `}
      >
        {data.details.map(({ title, description, rating }, index) =>
          rating !== null && description !== null ? (
            <li key={index}>
              <div
                css={css`
                  position: absolute;
                  left: 0;
                  margin-top: -4px;
                `}
              >
                <Indicator heading={headingMapping[index]} rating={rating} size={Size.large} />
              </div>
              <h3
                css={css`
                  margin: 0;
                  ${fontStyles.lion}
                  html[data-theme='dark'] & {
                    color: ${colors.sugar};
                  }
                `}
              >
                {title}
              </h3>
              <p
                css={css`
                  margin: ${spacing.centi} 0 ${spacing.regular} 0;
                  ${fontStyles.mouse}
                  html[data-theme='dark'] & {
                    color: ${colors.sugar};
                  }
                `}
              >
                {description}
              </p>
            </li>
          ) : null,
        )}
      </ul>
    </div>
  );
};

export default Overview;
