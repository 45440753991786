import { Capacitor, Plugins } from '@capacitor/core';

const { Theme } = Plugins;

export type Theme = 'light' | 'dark' | 'auto';

declare global {
  interface Window {
    nativeApp?: {
      setTheme: (theme: Theme) => void;
    };
  }
}

export const getInitialTheme = (): Theme => {
  return (localStorage.getItem('theme') as Theme) || 'auto';
};

export const setTheme = async (theme: Theme) => {
  localStorage.setItem('theme', theme);

  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === 'android') {
      try {
        await Theme.setTheme({ theme });
        if (theme === 'auto') {
          const systemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
          document.documentElement.setAttribute('data-theme', systemDark ? 'dark' : 'light');
        } else {
          document.documentElement.setAttribute('data-theme', theme);
        }
      } catch (error) {
        console.error('Error setting theme:', error);
      }
      return;
    } else if (window.nativeApp?.setTheme) {
      window.nativeApp.setTheme(theme);
      return;
    }
  }

  // Web logic
  if (theme === 'auto') {
    const systemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    document.documentElement.setAttribute('data-theme', systemDark ? 'dark' : 'light');
  } else {
    document.documentElement.setAttribute('data-theme', theme);
  }
};

export const setupThemeListener = () => {
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  const handleChange = () => {
    const currentTheme = getInitialTheme();
    if (currentTheme === 'auto') {
      setTheme('auto');
    }
  };

  mediaQuery.addEventListener('change', handleChange);
  return () => mediaQuery.removeEventListener('change', handleChange);
};

export const initializeTheme = () => {
  const savedTheme = getInitialTheme();
  setTheme(savedTheme);
  setupThemeListener();
};
