import { differenceInDays } from 'date-fns';
import { Capacitor } from '@capacitor/core';
import { Purchases, PurchasesOfferings, CustomerInfo } from '@revenuecat/purchases-capacitor';
import { posthog } from 'posthog-js';

import { pick, pickBy } from 'lodash';
import { Store } from 'pullstate';
import { SortingRule } from 'react-table';
import { InAppReview } from '@capacitor-community/in-app-review';
import { FilterKey, ColumnSelection, ColumnSelectionType } from './components/InvestmentTable';
import { TimeRange } from './types';
import * as storage from './storage';

const getWatchlistFromLocalstorageAndRemove = () => {
  const watchlistString = window.localStorage.getItem('watchlist');
  if (!watchlistString) return;
  const watchlist = JSON.parse(watchlistString);
  window.localStorage.removeItem('watchlist');
  return watchlist;
};

export const generalStoreId = 'GeneralStore';
interface General {
  isAppReady: boolean;
  appOpenDates?: string[];
  lastReviewPromptAttemptDate?: string;
  sessionWatchlistViewCount: number;
  watchlist: { [symbol: string]: boolean };
  isSubscriptionTakeoverShown: boolean;
  hasSeenSubscriptionTakeoverThisSession: boolean;
  isSearchOnboardingPopoverShown: boolean;
  searchOnboardingPopoverOpenedAt?: string;
  isBookmarkOnboardingPopoverShown: boolean;
  bookmarkOnboardingPopoverOpenedAt?: string;
  isKeyboardVisible: boolean;
}
export type SavedGeneral = Pick<
  General,
  | 'appOpenDates'
  | 'lastReviewPromptAttemptDate'
  | 'watchlist'
  | 'searchOnboardingPopoverOpenedAt'
  | 'bookmarkOnboardingPopoverOpenedAt'
>;
export const General = new Store<General>({
  isAppReady: false,
  sessionWatchlistViewCount: 0,
  watchlist: {},
  isSubscriptionTakeoverShown: false,
  hasSeenSubscriptionTakeoverThisSession: false,
  isSearchOnboardingPopoverShown: false,
  isBookmarkOnboardingPopoverShown: false,
  isKeyboardVisible: false,
});
export const initializeGeneral = async () => {
  General.createReaction(
    (s) => s,
    (store, draft) => {
      storage.set(
        generalStoreId,
        pick(store, [
          'appOpenDates',
          'lastReviewPromptAttemptDate',
          'watchlist',
          'searchOnboardingPopoverOpenedAt',
          'bookmarkOnboardingPopoverOpenedAt',
        ]),
      );

      const enoughDaysSinceFirstOpen =
        store.appOpenDates?.[0] && differenceInDays(new Date(), new Date(store.appOpenDates[0])) > 2;
      const enoughAppOpens = store.appOpenDates && store.appOpenDates.length > 2;
      const enoughDaysSinceLastPrompt =
        !store.lastReviewPromptAttemptDate ||
        differenceInDays(new Date(), new Date(store.lastReviewPromptAttemptDate)) > 24;

      if (
        Capacitor.isPluginAvailable('RateApp') &&
        enoughDaysSinceFirstOpen &&
        enoughAppOpens &&
        enoughDaysSinceLastPrompt &&
        store.sessionWatchlistViewCount > 1
      ) {
        InAppReview.requestReview();
        posthog.capture('Attempted to ask for review.');
        draft.lastReviewPromptAttemptDate = new Date().toISOString();
      }
    },
  );

  const saved = await storage.get<SavedGeneral>(generalStoreId);
  const localStorageWatchlist = getWatchlistFromLocalstorageAndRemove();

  General.update((s) => {
    s.appOpenDates = saved?.appOpenDates
      ? saved.appOpenDates.concat(new Date().toISOString())
      : [new Date().toISOString()];
    s.lastReviewPromptAttemptDate = saved?.lastReviewPromptAttemptDate;
    s.watchlist = saved?.watchlist || localStorageWatchlist || {};
    s.searchOnboardingPopoverOpenedAt = saved?.searchOnboardingPopoverOpenedAt;
    s.bookmarkOnboardingPopoverOpenedAt = saved?.bookmarkOnboardingPopoverOpenedAt;
  });
};

const tableOptionsStoreId = 'TableOptions';
type TableOptions =
  | {
      [id: string]: {
        sortingRule?: SortingRule<string>;
        columnSelections?: ColumnSelection[];
        filters?: Partial<Record<FilterKey, boolean>>;
      };
    }
  | Record<string, never>;
export const TableOptions = new Store<TableOptions>({
  portfolio: {},
});

export const initializeTableOptions = async () => {
  TableOptions.subscribe(
    (s) => s,
    (store) => {
      storage.set(tableOptionsStoreId, store);
    },
  );

  const saved = await storage.get<TableOptions>(tableOptionsStoreId);
  if (saved) {
    const savedWithInvalidSelectionsOmitted = pickBy(saved, ({ columnSelections }) => {
      if (!columnSelections) return true;
      return columnSelections.every(
        ({ type, timeRange }) =>
          !timeRange ||
          (Object.values(TimeRange).includes(timeRange) && Object.values(ColumnSelectionType).includes(type)),
      );
    });
    TableOptions.replace(savedWithInvalidSelectionsOmitted);
    TableOptions.update(() => {
      // trigger blank update for replace
    });
  }
};

export const entitlementIdentifier = 'full';
const purchaseStoreId = 'PurchaseStore';
interface PurchaseState {
  // Keep it named purchaserInfo for backwards compatibility
  purchaserInfo?: CustomerInfo;
  offerings?: PurchasesOfferings;
}
export const Purchase = new Store<PurchaseState>({});
Purchase.subscribe(
  (s) => s,
  (store) => {
    storage.set(purchaseStoreId, store);
  },
);

export const initializePurchase = async () => {
  const savedPurchaseStore = await storage.get<PurchaseState>(purchaseStoreId);

  if (savedPurchaseStore) {
    Purchase.update((s) => {
      s.offerings = savedPurchaseStore.offerings;
      s.purchaserInfo = savedPurchaseStore.purchaserInfo;
    });
  }

  (async () => {
    let offerings: PurchasesOfferings;
    let purchaserInfo: CustomerInfo;
    if (Capacitor.isNativePlatform()) {
      // Update these calls to use the new API:
      [offerings, purchaserInfo] = await Promise.all([
        Purchases.getOfferings(),
        (await Purchases.getCustomerInfo()).customerInfo,
      ]);
    } else {
      /*
      purchaserInfo = {
        entitlements: {
          active: {
            [entitlementIdentifier]: {
              isActive: true,
            },
          },
        },
      } as any;
      */

      // placeholder offerings
      offerings = {
        current: {
          monthly: {
            identifier: '$rc_monthly',
            packageType: 'MONTHLY',
            product: {
              price: 7.76,
              price_string: '$7.76',
            },
          },
          annual: {
            identifier: '$rc_annual',
            packageType: 'ANNUAL',
            product: {
              price: 37.73,
              price_string: '$37.73',
              intro_price: 0,
              intro_price_period_unit: 'MONTH',
              intro_price_period_number_of_units: 1,
            },
          },
        },
      } as any;
    }

    Purchase.update((s) => {
      s.offerings = offerings;
      s.purchaserInfo = purchaserInfo;
    });
  })();
};
