import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { colors, fontStyles, spacing } from '../../../styles';
import { getInsights } from '../../../apis/backend';
import * as stores from '../../../stores';
import externalLink from '../../../assets/externalLink.svg';
import exceptionalIcon from '../../RatingIndicator/defaultImages/exceptional.svg';
import unfavorableIcon from '../../RatingIndicator/defaultImages/unfavorable.svg';

interface Props {
  symbol: string;
}

const ALLOWED_FREE_SYMBOLS = ['META', 'AAPL'];

const AllInsightsPage: FC<Props> = ({ symbol }) => {
  const history = useHistory();
  const purchaserInfo = stores.Purchase.useState((s) => s.purchaserInfo);
  const isSubscribed = purchaserInfo?.entitlements?.active[stores.entitlementIdentifier]?.isActive;

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle subscription check
  useEffect(() => {
    if (!isSubscribed && !ALLOWED_FREE_SYMBOLS.includes(symbol)) {
      window.scrollTo(0, 0);
      stores.General.update((s) => {
        s.isSubscriptionTakeoverShown = true;
      });
      history.goBack();
    }
  }, [isSubscribed, symbol, history]);

  const { data, isLoading } = useQuery(['investments', symbol, 'insights'], async () => {
    const response = await getInsights(symbol);
    return Array.isArray(response) ? response : [];
  });

  if (isLoading || !data?.length) return null;

  return (
    <div>
      <h2 css={styles.heading}>Insights</h2>

      {data.map((insight, insightIndex) => {
        const date = new Date(insight.articleDate).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });

        return (
          <div key={insightIndex} css={[styles.insightContainer, insightIndex > 0 && styles.insightSeparator]}>
            <div css={styles.titleRow}>
              <a href={insight.fileUrl} target="_blank" rel="noopener noreferrer" css={styles.titleLink}>
                {insight.title}
                <img src={externalLink} css={styles.externalLinkIcon} alt="" />
              </a>
              <span css={styles.date}>{date}</span>
            </div>

            <p css={styles.summary}>{insight.summary}</p>

            <div css={styles.section}>
              <div css={[styles.sectionHeader, styles.favorableHeader]}>
                <img src={exceptionalIcon} css={styles.sectionIcon} alt="" /> Key Points
              </div>
              {insight.keyPoints.map((point, index) => (
                <a
                  key={index}
                  href={`${insight.fileUrl}#page=${point.page}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={styles.pointLink}
                >
                  <div css={styles.point}>
                    <div css={styles.pageNumber}>Page {point.page}</div>
                    <div css={styles.pointText}>{point.details}</div>
                  </div>
                </a>
              ))}
            </div>

            <div css={styles.section}>
              <div css={[styles.sectionHeader, styles.unfavorableHeader]}>
                <img src={unfavorableIcon} css={styles.sectionIcon} alt="" /> Potential Risks
              </div>
              {insight.potentialRisks.map((risk, index) => (
                <a
                  key={index}
                  href={`${insight.fileUrl}#page=${risk.page}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  css={styles.pointLink}
                >
                  <div css={styles.point}>
                    <div css={styles.pageNumber}>Page {risk.page}</div>
                    <div css={styles.pointText}>{risk.details}</div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const styles = {
  heading: css`
    margin: 0 0 ${spacing.regular} 0;
    ${fontStyles.shark}
  `,
  titleRow: css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: ${spacing.deci};
  `,
  titleLink: css`
    color: ${colors.petrolBlue};
    text-decoration: none;
    ${fontStyles.roboCat}
  `,
  externalLinkIcon: css`
    vertical-align: text-bottom;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: ${spacing.milli};
  `,
  date: css`
    color: ${colors.silver};
    ${fontStyles.roboCat}
  `,
  summary: css`
    margin: ${spacing.deci} 0;
    color: ${colors.tungsten};
    ${fontStyles.cat}
  `,
  section: css`
    margin-top: ${spacing.deka};
  `,
  sectionHeader: css`
    ${fontStyles.lion}
    margin-bottom: ${spacing.deci};
    display: flex;
    align-items: center;
  `,
  favorableHeader: css`
    color: ${colors.leaf};
  `,
  unfavorableHeader: css`
    color: ${colors.orange};
  `,
  sectionIcon: css`
    width: 20px;
    height: 20px;
    margin-right: ${spacing.milli};
    vertical-align: text-bottom;
  `,
  pointLink: css`
    text-decoration: none;
    display: block;

    &:hover {
      text-decoration: none;
    }
  `,
  point: css`
    margin-bottom: ${spacing.centi};
    padding: ${spacing.centi};
    border-radius: 8px;
    background: ${colors.fog};

    &:hover {
      background: ${colors.snow};
    }
  `,
  pageNumber: css`
    color: ${colors.petrolBlue};
    ${fontStyles.mouse}
    margin-bottom: ${spacing.milli};
  `,
  pointText: css`
    ${fontStyles.cat}
    color: ${colors.liquorice};
  `,
  insightContainer: css`
    margin-top: ${spacing.regular};
    padding-top: ${spacing.regular};
  `,
  insightSeparator: css`
    border-top: 1px solid ${colors.cloud};
  `,
};

export default AllInsightsPage;
