import { css } from '@emotion/react';
import { spacing, colors } from '../styles';

const Hr = () => (
  <hr
    css={css`
      margin: ${spacing.deka} 0;
      border: 1px solid ${colors.cloud};
      background-color: ${colors.cloud};
    `}
  />
);

export default Hr;
