import { format, isSameDay, isSameWeek } from 'date-fns';
import { last } from 'lodash';
import { TimeRange } from '../../../types';

const buildDayName = (chart?: ChartElement) => {
  if (!chart || !chart.date) return '';

  if (chart.minute) {
    if (isSameDay(chart.date, new Date())) {
      return format(chart.date, 'h:mm a') + ' EST';
    }
    if (isSameWeek(chart.date, new Date())) {
      return format(chart.date, 'EEEE h:mm a') + ' EST';
    }
    return format(chart.date, 'EEEE LLLL d, ha') + ' EST';
  }

  return isSameDay(chart.date, new Date()) ? 'Today' : format(chart.date, 'iiii');
};

export const buildDateText = (
  chart: ChartElement[],
  timeRange: Exclude<TimeRange, TimeRange.ytd>,
  selectedChartElement?: ChartElement,
) => {
  if (!selectedChartElement) {
    const textMapping = {
      [TimeRange.days1]: buildDayName(last(chart)),
      [TimeRange.weeks1]: 'Past\u00a0Week',
      [TimeRange.months1]: 'Past\u00a0Month',
      [TimeRange.months3]: 'Past\u00a03\u00a0Months',
      [TimeRange.years1]: 'Past\u00a0Year',
      [TimeRange.years5]: 'Past\u00a05\u00a0Years',
    };

    return textMapping[timeRange];
  }

  if (!selectedChartElement.date) return '';
  const textMapping = {
    [TimeRange.days1]: buildDayName(selectedChartElement),
    [TimeRange.weeks1]: buildDayName(selectedChartElement),
    [TimeRange.months1]: buildDayName(selectedChartElement),
    [TimeRange.months3]: format(selectedChartElement.date, 'EEEE LLLL d'),
    [TimeRange.years1]: format(selectedChartElement.date, 'EEEE LLLL d, y'),
    [TimeRange.years5]: format(selectedChartElement.date, 'EEEE LLLL d, y'),
  };
  return textMapping[timeRange];
};
