import { ReactNode } from 'react';
import { BasicInvestment, Rating } from '../../apis/backend';

export type FilterKey =
  | 'stocks'
  | 'funds'
  | 'large'
  | 'favorableGrowth'
  | 'favorableProfits'
  | 'favorableGrowth'
  | 'favorablePrice';

export const filterOptions: Record<
  FilterKey,
  {
    buttonComponent: ReactNode;
    filterFn: (investment: Partial<BasicInvestment>) => boolean;
  }
> = {
  stocks: {
    buttonComponent: 'Stocks',
    filterFn: (investment) => !!investment.category && ['STOCK', 'ADR'].includes(investment.category),
  },
  funds: {
    buttonComponent: 'Funds',
    filterFn: (investment) => !!investment.category && ['MUTUAL_FUND', 'ETF'].includes(investment.category),
  },
  large: {
    buttonComponent: 'Large Cap',
    filterFn: (investment) => (investment?.marketCap ?? 0) > 5 * 10 ** 9,
  },
  favorableProfits: {
    buttonComponent: 'Good profits',
    filterFn: (investment) =>
      !!investment?.bottomLine?.profitRating &&
      [Rating.Favorable, Rating.Exceptional].includes(investment?.bottomLine?.profitRating),
  },
  favorableGrowth: {
    buttonComponent: 'Good growth',
    filterFn: (investment) =>
      !!investment?.bottomLine?.growthRating &&
      [Rating.Favorable, Rating.Exceptional].includes(investment?.bottomLine?.growthRating),
  },
  favorablePrice: {
    buttonComponent: 'Good price',
    filterFn: (investment) =>
      !!investment?.bottomLine?.priceRating &&
      [Rating.Favorable, Rating.Exceptional].includes(investment?.bottomLine?.priceRating),
  },
};
