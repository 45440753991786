import { css } from '@emotion/react';
import { FC } from 'react';
import { fontStyles, colors, spacing } from '../../../styles';
import formatDisplay, { FormatterStyle } from '../../../utils/formatDisplay';
import ColoredPercentChange from '../../ColoredPercentChange';

interface Props {
  name?: string;
  price?: number;
  changeRatio?: number;
  dateText?: string;
}

const BasicInfo: FC<Props> = ({ name = '\u00A0', price, changeRatio, dateText }) => {
  const styles = {
    name: css`
      margin: 0;
      ${fontStyles.shark};
      html[data-theme='dark'] & {
        color: ${colors.sugar};
      }
    `,
    price: css`
      ${fontStyles.roboHorse};
      color: ${colors.tungsten};
      margin: ${spacing.centi} 0 ${spacing.deci} 0;
      html[data-theme='dark'] & {
        color: ${colors.sugar};
      }
    `,
    dateText: css`
      color: ${colors.silver};
      margin-left: ${spacing.centi};
      ${fontStyles.flea};
      html[data-theme='dark'] & {
        color: ${colors.sugar};
      }
    `,
  };
  return (
    <div>
      <h2 css={styles.name}>{name}</h2>
      <div css={styles.price}>{price ? formatDisplay(FormatterStyle.moneySmall, price) : '\u00A0'}</div>
      <div>
        <ColoredPercentChange
          value={changeRatio}
          css={css`
            ${fontStyles.roboHorse};
          `}
        />
        <span css={styles.dateText}>{dateText}</span>
      </div>
    </div>
  );
};

export default BasicInfo;
