import { css } from '@emotion/react';
import { FC, ComponentPropsWithoutRef } from 'react';
import { colors, fontStyles, spacing } from '../../styles';
import chevron from './chevron.svg';

type Props = ComponentPropsWithoutRef<'select'>;

const Select: FC<Props> = ({ children, ...props }) => (
  <select
    css={css`
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: url(${chevron}) no-repeat right transparent;

      outline: none;
      color: ${colors.petrolBlue};
      width: 100%;
      border: none;
      border-radius: 0;
      border-bottom: solid 1px ${colors.cloud};
      padding: 0 0 ${spacing.centi} 0;
      ${fontStyles.roboCat}
    `}
    {...props}
  >
    {children}
  </select>
);

export default Select;
