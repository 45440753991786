import { useQuery } from 'react-query';
import { css } from '@emotion/react';
import { FC, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { investmentIndex } from '../../apis/search';
import { colors, fontStyles, spacing } from '../../styles';
import magnifyingGlassUrl from '../Search/magnifyingGlass.svg';
import crossIcon from '../Search/cross.svg';
import * as stores from '../../stores';
import BookmarkButton from '../BookmarkButton';

interface Props {
  onResultClick?: (symbol: string, name: string) => void;
  autoFocus?: boolean;
  isAllowBookmark?: boolean;
  excludedSymbols?: string[];
  onStockAdded?: (stock: { symbol: string; name: string }) => void;
}

const OnboardingSearch: FC<Props> = ({ onResultClick, autoFocus, excludedSymbols = [], onStockAdded, ...props }) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState('');
  const facetFilters = excludedSymbols.map((symbol) => `objectID:-${symbol}`);
  const watchlist = stores.General.useState((s) => s.watchlist);

  const resultsQuery = useQuery(
    ['search', query],
    async () => {
      if (!query) return [];
      const results = (
        await investmentIndex.search(query, {
          facetFilters,
          hitsPerPage: 5,
        })
      ).hits;
      return results.filter((result: any) => !!result.name);
    },
    { enabled: !!query },
  );

  const isResultsShown = query && !resultsQuery.isLoading;

  const handleClickCross = () => {
    setQuery('');
    inputEl.current?.focus();
  };

  const handleResultClick = (symbol: string, name: string) => {
    const isInWatchlist = !!watchlist[symbol];

    if (isInWatchlist) {
      // Deseleccionar
      stores.General.update((s) => {
        const newWatchlist = { ...s.watchlist };
        delete newWatchlist[symbol];
        s.watchlist = newWatchlist;
      });
      toast('Removed from watchlist');
    } else {
      if (onResultClick) {
        onResultClick(symbol, name);
      }
      if (onStockAdded) {
        onStockAdded({ symbol, name });
      }
      toast('Added to watchlist');
    }

    setQuery('');
    inputEl.current?.focus();
  };

  return (
    <div
      css={css`
        position: relative;
      `}
      {...props}
    >
      <div>
        <span
          css={css`
            position: absolute;
            height: 50px;
            width: 50px;
            background-color: transparent;
            border: none;
            outline: none;
            padding: 14px;
            pointer-events: none;
          `}
        >
          <img src={magnifyingGlassUrl} alt="" />
        </span>
        <input
          autoFocus={autoFocus}
          ref={inputEl}
          type="text"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          placeholder="Search stocks"
          css={[
            css`
              ${fontStyles.dog};
              width: 100%;
              height: 50px;
              padding: 14px 16px 14px 50px;
              border: 2px solid ${colors.petrolBlue};
              border-radius: 8px;
              outline: none;
              background-color: var(--background-color);
              color: var(--text-color);
              -webkit-appearance: none;

              &::placeholder {
                color: var(--secondary-text);
              }
            `,
            isResultsShown &&
              css`
                border-radius: 8px 8px 0 0;
              `,
          ]}
        />
        {query.length > 0 && (
          <button
            type="button"
            onClick={handleClickCross}
            css={css`
              position: absolute;
              height: 50px;
              width: 50px;
              right: 0;
              top: 0;
              background-color: transparent;
              border: none;
              outline: none;
              cursor: pointer;
              padding: 18px;
            `}
            aria-label="cancel"
          >
            <img src={crossIcon} alt="" />
          </button>
        )}
      </div>

      {isResultsShown && (
        <div
          css={css`
            position: absolute;
            z-index: 1;
            width: 100%;
            background-color: var(--background-color);
            border: 1px solid var(--border-color);
            border-top: none;
            border-radius: 0 0 8px 8px;
          `}
        >
          {resultsQuery.data?.map((result: any) => (
            <div
              key={result.symbol}
              css={css`
                display: flex;
                align-items: center;
                padding: ${spacing.centi};
                border-bottom: 1px solid var(--border-color);

                &:last-child {
                  border-bottom: none;
                }

                &:hover {
                  background-color: var(--card-background);
                }
              `}
            >
              <button
                onClick={() => handleResultClick(result.symbol, result.name)}
                css={css`
                  flex: 1;
                  text-align: left;
                  padding: 0;
                  border: none;
                  background: none;
                  cursor: pointer;
                  color: var(--text-color);
                `}
              >
                <div
                  css={css`
                    ${fontStyles.lion};
                  `}
                >
                  {result.symbol}
                </div>
                <div
                  css={css`
                    ${fontStyles.mouse};
                    color: var(--secondary-text);
                  `}
                >
                  {result.name}
                </div>
              </button>
              <BookmarkButton symbol={result.symbol} category={result.category} name={result.name} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OnboardingSearch;
