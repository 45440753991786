import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { spacing, fontStyles, fontFamilies } from '../../styles';
import BottomLineExplanationTakeover from '../BottomLineExplanationTakeover';
import Button from '../Button';
import FeedbackTakeover from '../FeedbackTakeover';
import OnboardingSlide from './OnboardingSlide';

// Light theme images
import calendarLight from './images/calendar.svg';
import wiimLight from './images/wiim.svg';
import collectionsLight from './images/collections.svg';
import reportsLight from './images/reports.svg';
import discordLight from './images/discord.svg';

// Dark theme images
import calendarDark from './images/dark/calendar.svg';
import wiimDark from './images/dark/wiim.svg';
import collectionsDark from './images/dark/collections.svg';
import reportsDark from './images/dark/reports.svg';
import discordDark from './images/dark/discord.svg';
const OnboardingPage: FC = () => {
  const history = useHistory();
  const [isBottomLineExplanationShown, setIsBottomLineExplanationShown] = useState(false);
  const [isFeedbackTakeoverShown, setIsFeedbackTakeoverShown] = useState(false);

  const handleComplete = () => {
    localStorage.setItem('hasCompletedOnboarding', 'true');
  };

  const slides = [
    {
      lightImage: wiimLight,
      darkImage: wiimDark,
      description: 'Understand why stock prices move with curated news.',
      redirectPath: '/news',
    },
    {
      lightImage: discordLight,
      darkImage: discordDark,
      description: 'Get your investing questions answered with AI chat.',
      redirectPath: '/chat',
    },
    {
      lightImage: calendarLight,
      darkImage: calendarDark,
      description: 'Track long-term changes in your watchlist.',
      redirectPath: '/watchlist-search',
    },
    {
      lightImage: collectionsLight,
      darkImage: collectionsDark,
      description: 'Discover new investment opportunities with our curated collections.',
      redirectPath: '/search',
    },
    {
      lightImage: reportsLight,
      darkImage: reportsDark,
      description: 'Get the bottom line on company finances.',
      redirectPath: '/watchlist-search',
      onSlideClick: () => {
        handleComplete();
        setIsBottomLineExplanationShown(true);
      },
    },
  ];

  return (
    <div
      css={css`
        min-height: 100vh;
        background-color: var(--background-color);
        padding: ${spacing.regular};
        display: flex;
        flex-direction: column;
        position: relative;
        padding-bottom: 80px;
      `}
    >
      <BottomLineExplanationTakeover
        isShown={isBottomLineExplanationShown}
        onClose={() => {
          setIsBottomLineExplanationShown(false);
          history.push('/watchlist-search');
        }}
      />

      {/* Header */}
      <div
        css={css`
          text-align: center;
          margin-top: ${spacing.giga};
          margin-bottom: ${spacing.hecto};
        `}
      >
        <h1
          css={css`
            font-size: 30px;
            font-weight: 500;
            line-height: 30px;
            margin-bottom: ${spacing.regular};
            color: var(--text-color);
            font-family: ${fontFamilies.helveticaNowText};
          `}
        >
          Welcome to Bloom!
        </h1>
        <p
          css={css`
            ${fontStyles.dog};
          `}
        >
          How can we support your investing goals?
        </p>
      </div>

      {/* Slides */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${spacing.regular};
        `}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            css={css`
              ${index !== slides.length - 1 &&
              `
                        border-bottom: 1px solid var(--border-color);
                        padding-bottom: ${spacing.regular};
                      `}
            `}
          >
            <OnboardingSlide
              description={slide.description}
              lightImage={slide.lightImage}
              darkImage={slide.darkImage}
              redirectPath={slide.redirectPath}
              onComplete={handleComplete}
              onClick={slide.onSlideClick}
            />
          </div>
        ))}
      </div>

      {/* Footer/Actions */}
      <div
        css={css`
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          padding: ${spacing.regular};
          background-color: var(--background-color);
          margin-bottom: ${spacing.kilo};
        `}
      >
        <Button
          onClick={() => {
            localStorage.setItem('hasCompletedOnboarding', 'true');
            setIsFeedbackTakeoverShown(true);
          }}
          css={css`
            width: 100%;
          `}
        >
          Need something different?
        </Button>
      </div>

      <FeedbackTakeover
        isShown={isFeedbackTakeoverShown}
        onClose={() => {
          setIsFeedbackTakeoverShown(false);
          history.push('/portfolio');
        }}
      />
    </div>
  );
};

export default OnboardingPage;
