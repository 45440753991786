import { Capacitor } from '@capacitor/core';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { toast } from 'react-toastify';

export const usePromoCode = () => {
  const handlePromoCode = async () => {
    const platform = Capacitor.getPlatform();

    if (platform === 'ios') {
      try {
        window.open('https://apps.apple.com/redeem', '_blank');
        const { customerInfo } = await Purchases.getCustomerInfo();

        if (customerInfo.entitlements.active) {
          toast.success('Promo code processed successfully!');
        }
      } catch (error) {
        console.error('Error processing iOS promo code:', error);
        toast.error('Failed to process promo code');
      }
    } else if (platform === 'android') {
      try {
        window.open('https://play.google.com/redeem', '_system');
      } catch (error) {
        console.error('Error opening Play Store:', error);
      }
    } else {
      toast('Promo codes are only available on mobile devices');
    }
  };

  return { handlePromoCode };
};
