import { css } from '@emotion/react';
import { HTMLProps, FC } from 'react';
import ConditionalLink from '../ConditionalLink';
import { colors, fontStyles } from '../../styles';
import Card from '../Card';

interface Props extends HTMLProps<HTMLDivElement> {
  symbol?: string;
  name: string;
  weight: number;
}

const HoldingSymbolCard: FC<Props> = ({ symbol, name, weight, ...props }) => {
  const styles = {
    card: css`
      display: flex;
    `,
    left: css`
      flex: 1;
      min-width: 0;
    `,
    symbol: css`
      ${fontStyles.lion};
      color: ${colors.liquorice};
      html[data-theme='dark'] & {
        color: ${colors.sugar};
      }
    `,
    name: css`
      ${fontStyles.mouse};
      color: ${colors.silver};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      html[data-theme='dark'] & {
        color: ${colors.sugar};
      }
    `,
    right: css`
      text-align: right;
      ${fontStyles.cat};
      html[data-theme='dark'] & {
        color: ${colors.petrolBlue};
      }
    `,
  };

  return (
    <ConditionalLink to={{ pathname: symbol ? `/symbol/${symbol}` : undefined }}>
      <Card css={styles.card} {...props}>
        <div css={styles.left}>
          <div css={styles.symbol}>{symbol}</div>
          <div css={styles.name}>{name}</div>
        </div>

        <div css={styles.right}>{weight}%</div>
      </Card>
    </ConditionalLink>
  );
};

export default HoldingSymbolCard;
